//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/ripple/variables";
@import "@material/theme/variables";

$mdc-checkbox-mark-color: mdc-theme-prop-value(on-primary);
$mdc-checkbox-border-color: rgba(mdc-theme-prop-value(on-surface), .54);
$mdc-checkbox-disabled-color: rgba(mdc-theme-prop-value(on-surface), .26);
$mdc-checkbox-baseline-theme-color: secondary;

$mdc-checkbox-touch-area: 40px;
$mdc-checkbox-size: 18px;
$mdc-checkbox-ui-pct: percentage($mdc-checkbox-size / $mdc-checkbox-touch-area);
$mdc-checkbox-mark-stroke-size: 2/15 * $mdc-checkbox-size;
$mdc-checkbox-border-width: 2px;
$mdc-checkbox-transition-duration: 90ms;
$mdc-checkbox-item-spacing: 4px;
$mdc-checkbox-focus-indicator-opacity: map-get($mdc-ripple-dark-ink-opacities, focus);

// Manual calculation done on SVG
$mdc-checkbox-mark-path-length_: 29.7833385;
$mdc-checkbox-indeterminate-checked-easing-function_: cubic-bezier(.14, 0, 0, 1);
