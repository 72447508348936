﻿@import "Variables";

@mixin maof-space($amount: 1, $directions: (top, right, bottom, left)) {
    @include maof-distance(space, $amount, $directions);
}

@mixin maof-pad($amount: 1, $directions: (top, right, bottom, left)) {
    @include maof-distance(pad, $amount, $directions);
}

@mixin maof-distance($type, $amount: 1, $directions: (top, right, bottom, left)) {
    $type: if($type == space, margin, if($type == pad, padding, $type));
    @if index($directions, top) != null and
        index($directions, bottom) != null and
        index($directions, right) != null and
        index($directions, left) != null {
        #{$type}: ceil($amount * $maof-unit);
    } @else {
        @each $direction in $directions {
            #{$type}-#{$direction}: ceil($amount * $maof-unit);
        }
    }
}