//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/animation/variables";

$mdc-select-arrow-padding: 26px;
$mdc-select-label-padding: 16px;
$mdc-select-border-radius: 4px;

$mdc-select-ink-color: rgba(mdc-theme-prop-value(on-surface), .87);
$mdc-select-disabled-ink-color: rgba(mdc-theme-prop-value(on-surface), .37);
$mdc-select-disabled-arrow-color: mdc-theme-prop-value(on-surface);

$mdc-select-label-color: rgba(mdc-theme-prop-value(on-surface), .6);
$mdc-select-focused-label-color: rgba(mdc-theme-prop-value(primary), .87);
$mdc-select-disabled-label-color: rgba(mdc-theme-prop-value(on-surface), .37);

$mdc-select-bottom-line-idle-color: rgba(mdc-theme-prop-value(on-surface), .42);
$mdc-select-bottom-line-hover-color: rgba(mdc-theme-prop-value(on-surface), .87);

$mdc-select-box-fill-color: mix(mdc-theme-prop-value(on-surface), mdc-theme-prop-value(surface), 4%);
$mdc-select-box-disabled-fill-color: mix(mdc-theme-prop-value(on-surface), mdc-theme-prop-value(surface), 2%);

$mdc-select-outlined-idle-border: rgba(mdc-theme-prop-value(on-surface), .24);

// should be .06 after mdc-select opacity is applied
$mdc-select-outlined-disabled-border: rgba(mdc-theme-prop-value(on-surface), .16);
$mdc-select-outlined-hover-border: rgba(mdc-theme-prop-value(on-surface), .87);

$mdc-select-outlined-label-position-y: 130%;
$mdc-select-outlined-dense-label-position-y: 110%;
