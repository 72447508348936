﻿/// <reference path="../../App" />

@mixin maof-table {
    display: table;

    &--fluid {
        width: 100%;
    }

    &--fixed {
        table-layout: fixed;
    }
}

@mixin maof-table-section {
    display: flex;
    flex-flow: row wrap;

    &__section {
        display: inline-flex;
        flex: 1 1 auto;
        align-items: center;

        &--align-start {
            justify-content: flex-start;
        }

        &--align-end {
            justify-content: flex-end;
        }
    }
}

@mixin maof-table-search-field {
    width: 100%;

    &--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
        margin: 0;
    }

    &--outlined {
        @include mdc-text-field-hover-outline-color(maof-color(medium1));
        @include mdc-notched-outline-idle-color(#dcdcdc);
        @include mdc-text-field-outline-corner-radius_(28px);

        .mdc-text-field__input {
            @include maof-pad(10 / 3, (right, left));
        }

        .mdc-floating-label {
            @include mdc-rtl-reflexive-position(left, 52px);
        }

        .mdc-notched-outline {
            /*@include mdc-elevation(5, #888);*/
            @include mdc-notched-outline-stroke-width(1.5px);

            &__idle {
                @include mdc-elevation(5, #888);
            }
        }
    }
}

@mixin maof-table-search-item {
    @include mdc-rtl-reflexive-property(padding, 12px, 20px);
    @include mdc-chip-ink-color(rgba(0, 0, 0, .54));
    @include mdc-chip-fill-color(rgba(#212121, .08));
    @include mdc-chip-leading-icon-color(maof-color(reset), 1);

    &:first-child {
        @include mdc-rtl-reflexive-box(margin, right, 4px);
    }

    .mdc-chip__icon {
        $iconPadding: 6px;
        position: relative;

        &::before {
            content: "";
            background-color: maof-color(medium1);
            position: absolute;
            width: 100%;
            height: 100%;
            z-index: -1;
            border-radius: 100%;
            padding: $iconPadding;
            left: -$iconPadding;
            top: -$iconPadding;
        }

        &--leading {
            @include mdc-rtl-reflexive-property(margin, -5px, 10px + $iconPadding);
        }
    }
}

@mixin maof-table-multi-action {
    width: 40px;
    /*position: relative;
    z-index: 1;*/
    @include maof-pad(0.333, (right, left));
    @include maof-pad(0, (top, bottom));

    .mdc-checkbox {
        z-index: 1;
        @include mdc-states-base-color(maof-color(primary));
        @include mdc-checkbox-container-colors($mdc-checkbox-border-color, transparent, maof-color(primary));
        @include mdc-checkbox-ink-color(maof-color(reset));
        @include mdc-checkbox-focus-indicator-color(maof-color(primary));
    }
}

@mixin maof-table-row-states($baseColor, $activeColor, $selector: &) {
    $row: &;
    $selector: unquote($selector);

    @at-root {
        #{$selector} {
            cursor: pointer;
            transition: background-color $mdc-states-wash-duration linear, box-shadow $mdc-ripple-fade-out-duration linear;

            &:hover, &#{$row}--open-actions {
                background-color: rgba($baseColor, mdc-states-opacity($baseColor, hover));
            }

            &:active:not(#{$row}--open-actions) {
                transition: background-color $mdc-states-wash-duration linear, box-shadow $mdc-ripple-fade-in-duration linear;
                box-shadow: 0 0 1000px rgba($baseColor, mdc-states-opacity($baseColor, press) - mdc-states-opacity($baseColor, hover)) inset;
            }

            &#{$row}--activated {
                $opacity: 2 * mdc-states-opacity($activeColor, hover);
                background-color: rgba($activeColor, $opacity);

                &:hover, &#{$row}--open-actions {
                    background-color: rgba($activeColor, mdc-states-opacity($activeColor, hover) + $opacity);
                }

                &:active:not(#{$row}--open-actions) {
                    box-shadow: 0 0 1000px rgba($activeColor, mdc-states-opacity($activeColor, press) - mdc-states-opacity($activeColor, hover)) inset;
                }
            }
        }
    }
}

@mixin maof-table-rows-select {
    height: initial;
    background-position: right 2px bottom 5px;
    @include maof-space(5 / 3, (right, left));
    @include mdc-select-ink-color(rgba(0,0,0,.54));

    @include mdc-rtl {
        background-position: left 2px bottom 5px;
    }

    &__native-control {
        @include mdc-typography(caption);
        @include maof-pad(0, (top, bottom));
        line-height: initial;
        border-bottom: 0;
        @include mdc-rtl-reflexive-property(padding, 0, 14px);
    }
}

@mixin maof-table-pagination-buttons {
    @include mdc-rtl-reflexive-property(margin, 40px, 0);

    a {
        color: inherit;
        text-decoration: none;
        width: 17px;
        display: inline-block;

        i {
            vertical-align: middle;

            @include mdc-rtl {
                transform: rotateZ(180deg);
            }
        }

        + a {
            @include mdc-rtl-reflexive-property(margin, 20px, 0);
        }
    }
}
