//
// Copyright 2016 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

$mdc-ripple-fade-in-duration: 75ms;
$mdc-ripple-fade-out-duration: 150ms;
$mdc-ripple-translate-duration: 225ms;
$mdc-states-wash-duration: 15ms;

$mdc-ripple-dark-ink-opacities: (
  hover: .04,
  focus: .12,
  press: .16,
  selected: .08,
  activated: .12
) !default;

$mdc-ripple-light-ink-opacities: (
  hover: .08,
  focus: .24,
  press: .32,
  selected: .16,
  activated: .24
) !default;

// Legacy

$mdc-ripple-pressed-dark-ink-opacity: .16;
$mdc-ripple-pressed-light-ink-opacity: .32;
