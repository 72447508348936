//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//
$mdc-text-field-error: #b00020;
$mdc-text-field-fullwidth-bottom-line-color: rgba(mdc-theme-prop-value(on-surface), .12);
$mdc-text-field-disabled-border: rgba(#231f20, .26);
$mdc-text-field-disabled-icon: rgba(mdc-theme-prop-value(on-surface), .3);
$mdc-text-field-bottom-line-hover: rgba(mdc-theme-prop-value(on-surface), .87);
$mdc-text-field-bottom-line-idle: rgba(mdc-theme-prop-value(on-surface), .42);
$mdc-text-field-label: rgba(mdc-theme-prop-value(on-surface), .6);
$mdc-text-field-placeholder: rgba(mdc-theme-prop-value(on-surface), .6);

$mdc-text-field-ink-color: rgba(mdc-theme-prop-value(on-surface), .87);
$mdc-text-field-helper-text-color: rgba(mdc-theme-prop-value(on-surface), .6);
$mdc-text-field-icon-color: rgba(mdc-theme-prop-value(on-surface), .54);
$mdc-text-field-focused-label-color: rgba(mdc-theme-prop-value(primary), .87);

$mdc-text-field-disabled-label-color: rgba(mdc-theme-prop-value(on-surface), .37);
$mdc-text-field-disabled-ink-color: rgba(mdc-theme-prop-value(on-surface), .37);
$mdc-text-field-disabled-helper-text-color: rgba(mdc-theme-prop-value(on-surface), .37);

$mdc-text-field-box-background: mix(mdc-theme-prop-value(on-surface), mdc-theme-prop-value(surface), 4%);
$mdc-text-field-box-disabled-background: mix(mdc-theme-prop-value(on-surface), mdc-theme-prop-value(surface), 2%);
$mdc-text-field-box-secondary-text: rgba(mdc-theme-prop-value(on-surface), .6);

$mdc-text-field-outlined-idle-border: rgba(mdc-theme-prop-value(on-surface), .24);
$mdc-text-field-outlined-disabled-border: rgba(mdc-theme-prop-value(on-surface), .06);
$mdc-text-field-outlined-hover-border: rgba(mdc-theme-prop-value(on-surface), .87);

$mdc-textarea-border: rgba(mdc-theme-prop-value(on-surface), .73);
$mdc-textarea-background: rgba(mdc-theme-prop-value(surface), 1);
// cannot be transparent because multiline textarea input
// will make text unreadable
$mdc-textarea-disabled-background: rgba(249, 249, 249, 1);

$mdc-text-field-border-radius: 4px !default;

$mdc-text-field-box-label-position-y: 50%;
$mdc-text-field-box-dense-label-position-y: 70%;
$mdc-text-field-dense-label-scale: .923;
$mdc-text-field-outlined-label-position-y: 130%;
$mdc-text-field-outlined-dense-label-position-y: 110%;
$mdc-text-field-outlined-with-leading-icon-label-position-x: 32px;
$mdc-text-field-outlined-dense-with-leading-icon-label-position-x: 21px;
$mdc-text-field-textarea-label-position-y: 50%;
// Note that the scale factor is an eyeballed approximation of what's shown in the mocks.
$mdc-text-field-textarea-label-scale: .923;
