﻿@mixin placeholder {
    &::-webkit-input-placeholder { /* Chrome/Opera/Safari */
        @content
    }

    &::-moz-placeholder { /* Firefox 19+ */
        @content
    }

    &:-ms-input-placeholder { /* IE 10+ */
        @content
    }

    &:-moz-placeholder { /* Firefox 18- */
        @content
    }
}
