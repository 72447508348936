﻿@mixin clearfix {
    zoom: 1; /* For IE 6/7 (trigger hasLayout) */
    @at-root {
        %clearfix-pseudo {
            content: "";
            display: table;
        }
    }

    &::before {
        @extend %clearfix-pseudo;
    }

    &::after {
        @extend %clearfix-pseudo;
        clear: both;
    }
}