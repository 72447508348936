﻿@import "../node_modules/@material/elevation/mdc-elevation";
@import "../node_modules/@material/ripple/mdc-ripple";
@import "../node_modules/@material/typography/mdc-typography";
@import "../node_modules/@material/icon-button/mdc-icon-button";
@import "../node_modules/@material/fab/mdc-fab";
@import "../node_modules/@material/button/mdc-button";
@import "../node_modules/@material/list/mdc-list";
@import "../node_modules/@material/checkbox/mdc-checkbox";
@import "../node_modules/@material/notched-outline/mdc-notched-outline";
@import "../node_modules/@material/textfield/mdc-text-field";
@import "../node_modules/@material/select/mdc-select";
@import "../node_modules/@material/drawer/mdc-drawer";
@import "../node_modules/@material/top-app-bar/mdc-top-app-bar";
@import "../node_modules/@material/chips/mdc-chips";
@import "../node_modules/@material/menu/mdc-menu";
@import "../node_modules/@material/linear-progress/mdc-linear-progress";
/// <reference path="../node_modules/@material/rtl/mixins" />

@mixin mdc-typography-prop($prop, $style-prop) {
    #{$prop}: map-get(map-get($mdc-typography-styles, $style-prop), $prop);
}

.fas, .far {
    font-size: 18px;
    letter-spacing: normal;
    text-transform: none;
    display: inline-block;
    white-space: nowrap;
    word-wrap: normal;
    text-align: center;
    line-height: 24px;
    vertical-align: middle;
}

.mdc-list-item__graphic {
    @include mdc-rtl-reflexive-box(margin, right, 49px - $mdc-list-side-padding, ".mdc-list-group__subheader");

    &.fas, &.far {
        @include mdc-typography-prop(line-height, subtitle2);
    }
}

.mdc-chip__checkmark, .mdc-chip__icon--leading {
    @include mdc-rtl-reflexive(margin-left, -4px, margin-right, 4px);
}

.mdc-list-item__graphic {
    @include mdc-rtl-reflexive-box(margin, right, $mdc-list-text-offset - $mdc-list-side-padding - 40px, ".mdc-list--avatar-list .mdc-list-item");
}
