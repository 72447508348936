﻿@import "Distance/Mixins";

// TODO - save bytes using extend for similar rules
@each $distanceType in ( space, pad ) {
    $baseClass: ".maof-";
    $baseSelector: #{$baseClass}#{$distanceType};

    /*#{$baseSelector} {
        @include maof-distance($distanceType);
    }*/

    @each $unitName, $unitValue in $maof-units {
        $unitSelector: #{$baseSelector}--#{$unitName};

        /*#{$baseSelector}#{$unitSelector} {
            @include maof-distance($distanceType, $unitValue);
        }*/
    }

    @each $axis, $directions in ( vertical: (top, bottom), horizontal: (right, left) ) {
        $axisSelector: #{$baseSelector}--#{$axis};

        /*#{$axisSelector} {
            @include maof-distance($distanceType, 1, $directions);
        }*/

        @each $unitName, $unitValue in $maof-units {
            $unitSelector: #{$baseSelector}--#{$unitName};

            /*#{$axisSelector}#{$unitSelector} {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
        }
    }

    @each $direction in ( top, right, bottom, left ) {
        $directionSelector: #{$baseSelector}--#{$direction};

        /*#{$directionSelector} {
            @include maof-distance($distanceType, 1, $direction);
        }*/

        @each $unitName, $unitValue in $maof-units {
            $unitSelector: #{$baseSelector}--#{$unitName};

            /*#{$directionSelector}#{$unitSelector} {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
        }
    }
}