﻿/// <reference path="../App" />

.maof-header {
    @include mdc-top-app-bar-ink-color(maof-color(dark));
    @include mdc-top-app-bar-icon-ink-color(maof-color(dark));
    @include mdc-top-app-bar-fill-color(maof-color(light2));

    .mdc-top-app-bar {
        &__title {
            position: relative;
            overflow: visible;
            line-height: 1;

            a {
                color: inherit;
                text-decoration: none;
            }
        }
    }

    &__breadcrumbs {
        margin: 5px 0 0;
        padding: 0;
        font-size: 12px;
        color: rgba(0, 0, 0, .54);
        list-style: none;

        li {
            display: inline-block;

            a {
                vertical-align: middle;

                &:hover {
                    text-decoration: underline;
                }
            }

            + li {
                &::before {
                    content: "navigate_next";
                    font-family: "Material Icons";
                    font-weight: normal;
                    font-style: normal;
                    line-height: 1;
                    letter-spacing: normal;
                    text-transform: none;
                    display: inline-block;
                    -webkit-font-feature-settings: "liga";
                    -webkit-font-smoothing: antialiased;
                    vertical-align: middle;

                    [dir="rtl"] & {
                        content: "navigate_before";
                    }
                }
            }
        }
    }
}