//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/theme/variables";

$mdc-temporary-drawer-fill-color: $mdc-theme-background !default;
$mdc-temporary-drawer-ink-color: map-get($mdc-theme-property-values, text-primary-on-background) !default;

$mdc-temporary-drawer-scrim-color: #000 !default;
$mdc-temporary-drawer-scrim-opacity: .6 !default;

$mdc-persistent-drawer-fill-color: $mdc-theme-background !default;
$mdc-persistent-drawer-ink-color: map-get($mdc-theme-property-values, text-primary-on-background) !default;

$mdc-permanent-drawer-fill-color: $mdc-theme-background !default;
$mdc-permanent-drawer-ink-color: map-get($mdc-theme-property-values, text-primary-on-background) !default;
