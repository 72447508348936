﻿/// <reference path="../App" />

#elClassrooms {
    height: 100%;
    text-align: center;
    position: relative;

    > ul {
        height: 100%;
        display: table;
        table-layout: fixed;

        @include mdc-rtl-reflexive-box(margin, left, -24px);

        > li {
            height: 100%;
            display: table-cell;
            vertical-align: top;

            &:not(:last-child) {
                @include mdc-rtl-reflexive-box(border, right, 1px solid maof-color(neutral));
            }
        }
    }

    &.maof-drag--active {
        pointer-events: none;
    }
}

.maof-classroom {
    $classroom: &;
    $textField: ".mdc-text-field";
    $sortableHelper: ".ui-sortable-helper";

    // Increases specificity level without utilizing the !important keyword
    @mixin important {
        @at-root {
            #elClassrooms & {
                @content
            }
        }
    }

    padding: 0 24px;
    padding-top: 106px;
    box-sizing: border-box;
    width: 288px;
    height: 100%;
    position: relative;

    &__title {
        &#{$classroom}__item {
            position: absolute;
            right: 24px;
            left: 24px;
            top: 0;
            margin-bottom: 0;
            padding-top: 14px;
            padding-bottom: 14px;
            @include mdc-elevation(5, #888);
            transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1), transform .25s cubic-bezier(.4, 0, .2, 1);
            z-index: 1;

            #{$textField} {
                @include important {
                    margin-top: 2px;
                }
            }

            &#{&}--new {
                opacity: 0;
                visibility: hidden;
                transform: translateX(50%);
            }
        }
    }
    /* #region FABs */
    @at-root {
        %fab-behavior {
            position: absolute;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: opacity .18s cubic-bezier(.4, 0, .2, 1), visibility .18s cubic-bezier(.4, 0, .2, 1), transform .18s cubic-bezier(.4, 0, .2, 1);

            #{$classroom}:hover & {
                opacity: 1;
                visibility: visible;
            }

            #{$classroom} #{$classroom}__item--active ~ & {
                opacity: 0;
                visibility: hidden;
            }
        }
    }

    &__new {
        @extend %fab-behavior;
        left: -20px;
        top: 25px;
        z-index: 1;
        transform: translateX(50%);

        #{$classroom}:hover & {
            transform: translateX(0);
        }

        #{$classroom} #{$classroom}__item--active ~ & {
            transform: translateX(50%);
        }
    }

    &__add {
        @extend %fab-behavior;
        top: 80px;
        right: 0;
        left: 0;
        margin: 0 auto;
        transform: translateY(-25%);

        #{$classroom}:hover & {
            transform: translateY(0);
        }

        #{$classroom} #{$classroom}__item--active ~ & {
            transform: translateY(-25%);
        }
    }
    /* #endregion */
    > ul {
        height: 100%;

        > li {
        }
    }

    &__item {
        $item: &;
        $menu: ".mdc-menu";
        cursor: move;
        position: relative;
        padding: 29px 45px;
        color: rgba(0, 0, 0, .54);
        font-size: 20px;
        line-height: 28px;
        margin-bottom: 5px;
        background: white;
        overflow: hidden;
        @include mdc-elevation(3, #888);
        /*@include mdc-ripple-surface;*/
        @include mdc-ripple-radius-bounded;
        @include mdc-states;
        transition: opacity .25s cubic-bezier(.4, 0, .2, 1), visibility .25s cubic-bezier(.4, 0, .2, 1), transform .25s cubic-bezier(.4, 0, .2, 1);

        #{$textField} {
            @include important {
                display: block;
                margin-bottom: 0;
                margin-top: 0;
                line-height: 1;
                height: auto;
                pointer-events: none;
                @include mdc-text-field-bottom-line-color(transparent);

                .mdc-floating-label {
                    left: 0;
                }

                #{$textField}__input {
                    padding-top: 0;
                    text-align: center;
                    transition: border-color .15s cubic-bezier(.4, 0, .2, 1);
                }

                .mdc-line-ripple {
                    display: none;
                }
            }
        }

        &__actions {
            @at-root {
                %actions-visible {
                    opacity: 1;
                    visibility: visible;
                    pointer-events: all;
                }

                %actions-hidden {
                    opacity: 0;
                    visibility: hidden;
                    pointer-events: none;
                }
            }

            @extend %actions-hidden;
            position: absolute;
            bottom: 5px;
            top: 5px;
            left: 5px;
            transition: opacity .18s cubic-bezier(.4, 0, .2, 1), visibility .18s cubic-bezier(.4, 0, .2, 1);

            a {
                display: block;
                position: absolute;
                left: 0;
                text-decoration: none;
                @include mdc-icon-button-size(20px, 20px, 10px);

                &:first-of-type {
                    top: 0;
                }

                &:last-of-type {
                    bottom: 0;
                }
            }
        }

        &:hover {
            #{$item}__actions--hover {
                @extend %actions-visible;
            }
        }

        &#{&}--active {
            cursor: auto;

            &::before, &::after {
                display: none;
            }

            #{$textField} {
                @include important {
                    pointer-events: all;
                    @include mdc-text-field-bottom-line-color($mdc-text-field-bottom-line-idle);

                    .mdc-line-ripple {
                        display: block;
                    }
                }
            }

            #{$item}__actions {
                &--active {
                    @extend %actions-visible;
                }

                &--hover {
                    @extend %actions-hidden;
                }
            }
        }

        &#{&}--new {
            opacity: 0;
            visibility: hidden;
            transform: translateY(-25%);
        }

        #{$menu} {
            right: 0;
            left: 0;
            margin-top:20px;
            @include mdc-elevation(5, #888);

            & .mdc-menu__items {
                padding: 0;
                box-shadow: 0 3px 6px #888;
            }

            & .mdc-list-item {
                border-bottom: 1px solid #000;
            }
        }
    }

    &#{$sortableHelper} {
        #{$classroom} {
            &__title {
                &#{$classroom}__item {
                    padding-top: 31px;
                    padding-bottom: 31px;
                    right: 0;
                    left: 0;
                    @include mdc-elevation(7, #888);

                    > :not(:first-child) {
                        @include important {
                            display: none;
                        }
                    }
                }
            }

            &__item {
                margin-bottom: 0;
                @include maof-fill-color(primary);
                color: white;
                @include mdc-elevation(5, #888);

                #{$textField} {
                    &__input {
                        color: white;

                        @include placeholder {
                            color: white;
                        }
                    }
                }

                &::before, &::after, &__actions {
                    display: none;
                }
            }
        }
    }
}

.ui-sortable-placeholder {
    min-width: 288px;
}
