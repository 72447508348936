//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "./functions";

$mdc-typography-font-family: Roboto, sans-serif !default;

$mdc-typography-base: (
  font-family: $mdc-typography-font-family,
  -moz-osx-font-smoothing: grayscale,
  -webkit-font-smoothing: antialiased,
);

$mdc-typography-font-weight-values: (
  thin: 100,
  light: 300,
  regular: 400,
  medium: 500,
  bold: 700,
  black: 900
) !default;

$mdc-typography-styles: mdc-typography-set-styles_(
  $mdc-typography-base,
  (
    headline1: (
      font-size: 6rem, // 96sp
      line-height: 6rem,
      font-weight: map-get($mdc-typography-font-weight-values, light),
      letter-spacing: mdc-typography-get-letter-spacing_(-1.5, 6),
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline2: (
      font-size: 3.75rem, // 60sp
      line-height: 3.75rem,
      font-weight: map-get($mdc-typography-font-weight-values, light),
      letter-spacing: mdc-typography-get-letter-spacing_(-.5, 3.75),
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline3: (
      font-size: 3rem, // 48px
      line-height: 3.125rem, // 50px
      font-weight: map-get($mdc-typography-font-weight-values, regular),
      letter-spacing: normal,
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline4: (
      font-size: 2.125rem, // 34sp
      line-height: 2.5rem, // 40sp
      font-weight: map-get($mdc-typography-font-weight-values, regular),
      letter-spacing: mdc-typography-get-letter-spacing_(.25, 2.125),
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline5: (
      font-size: 1.5rem, // 24sp
      line-height: 2rem, // 32sp
      font-weight: map-get($mdc-typography-font-weight-values, regular),
      letter-spacing: normal,
      text-decoration: inherit,
      text-transform: inherit
    ),
    headline6: (
      font-size: 1.25rem, // 20sp
      line-height: 2rem, // 32sp
      font-weight: map-get($mdc-typography-font-weight-values, medium),
      letter-spacing: mdc-typography-get-letter-spacing_(.25, 1.25),
      text-decoration: inherit,
      text-transform: inherit
    ),
    subtitle1: (
      font-size: 1rem, // 16sp
      line-height: 1.75rem, // 28sp
      font-weight: map-get($mdc-typography-font-weight-values, regular),
      letter-spacing: mdc-typography-get-letter-spacing_(.15, 1),
      text-decoration: inherit,
      text-transform: inherit
    ),
    subtitle2: (
      font-size: .875rem, // 14sp
      line-height: 1.375rem, // 22sp
      font-weight: map-get($mdc-typography-font-weight-values, medium),
      letter-spacing: mdc-typography-get-letter-spacing_(.1, .875),
      text-decoration: inherit,
      text-transform: inherit
    ),
    body1: (
      font-size: 1rem, // 16sp
      line-height: 1.5rem, // 24sp
      font-weight: map-get($mdc-typography-font-weight-values, regular),
      letter-spacing: mdc-typography-get-letter-spacing_(.5, 1),
      text-decoration: inherit,
      text-transform: inherit
    ),
    body2: (
      font-size: .875rem, // 14sp
      line-height: 1.25rem, // 20sp
      font-weight: map-get($mdc-typography-font-weight-values, regular),
      letter-spacing: mdc-typography-get-letter-spacing_(.25, .875),
      text-decoration: inherit,
      text-transform: inherit
    ),
    caption: (
      font-size: .75rem, // 12sp
      line-height: 1.25rem, // 20sp
      font-weight: map-get($mdc-typography-font-weight-values, regular),
      letter-spacing: mdc-typography-get-letter-spacing_(.4, .75),
      text-decoration: inherit,
      text-transform: inherit
    ),
    button: (
      font-size: .875rem, // 14sp
      line-height: 2.25rem, // 36sp
      font-weight: map-get($mdc-typography-font-weight-values, medium),
      letter-spacing: mdc-typography-get-letter-spacing_(1.25, .875),
      text-decoration: none,
      text-transform: uppercase
    ),
    overline: (
      font-size: .75rem, // 12sp
      line-height: 2rem, // 32sp
      font-weight: map-get($mdc-typography-font-weight-values, medium),
      letter-spacing: mdc-typography-get-letter-spacing_(2, .75),
      text-decoration: none,
      text-transform: uppercase
    ),
  )
) !default;
