﻿$maof-colors: (
    primary: #ff8000,
    secondary: #0d92df,
    reset: #fff,
    light1: #fafafa,
    light2: #efefef,
    light3: #ececec,
    neutral: #dcdcdc,
    medium1: #7d7d7d,
    medium2: #727272,
    dark: #212121
) !default;