﻿/// <reference path="../App" />

.maof-sidenav {
    $root: &;
    $subMenu: #{$root}__wrapper > #{$root}__submenu;
    $listItem: ".mdc-list-item";
    position: relative;
    z-index: 1;
    @include mdc-list-item-primary-text-ink-color(maof-color(dark));
    @include mdc-list-group-subheader-ink-color(rgba(0, 0, 0, .54));
    @include mdc-list-item-graphic-ink-color(rgba(0, 0, 0, .54));
    @include mdc-drawer-fill-color(maof-color(light1));

    &__wrapper {
        overflow: hidden;

        #{$root}:not([initialized]) & {
            height: 0;
        }
    }

    &__submenu {
        #{$root}[initialized] & {
            transition: margin-top 235ms cubic-bezier(.4, 0, .2, 1);
        }
    }

    .mdc-list-group__subheader {
        @include mdc-typography(subtitle2);
    }

    #{$listItem} {
        &__graphic {
            font-size: 14px;

            svg {
                fill: rgba(0, 0, 0, .54);
            }

            &[data-indicator] {
                transition: transform 235ms cubic-bezier(.4, 0, .2, 1);

                &[data-indicator="closed"] {
                    transform: rotateZ(90deg);
                }
            }
        }
    }
    // 1st level
    nav > #{$subMenu} {
        > #{$listItem} {
            &--activated {
                @include mdc-states-base-color(rgba(255, 255, 255, .5));
                @include maof-ink-color(reset);
                @include maof-fill-color(primary);

                svg {
                    fill: maof-color(reset);
                }

                i {
                    @include maof-ink-color(reset);
                }

                &:not(:hover):not(.mdc-ripple-upgraded--background-focused)::before {
                    opacity: 0;
                }
            }
        }
        // 2nd level
        > #{$subMenu} {
            > #{$listItem} {
            }
            //3rd level
            > #{$subMenu} {
                > #{$listItem} {
                    /*&--activated {
                        @include maof-ink-color(primary);
                    }*/
                }
            }
        }
        // 2nd, 3rd, ...nth level
        #{$subMenu} {
            #{$listItem} {
                @include mdc-states;

                &--activated {
                    &#{$root}__bottom {
                        @include maof-ink-color(primary);
                    }

                    &:not(:focus):not(:hover)::before {
                        opacity: 0;
                    }
                }
            }
        }
    }

    .mdc-drawer {
        &__drawer {
            @include mdc-rtl-reflexive-box(border, right, 0);
            @include mdc-elevation(5, black);
        }

        &__content {
            overflow: auto;
            max-height: 100%;
        }
    }
}
