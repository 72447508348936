// Copyright 2016 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.

@import "@material/ripple/common";
@import "@material/ripple/mixins";
@import "@material/ripple/variables";
@import "@material/typography/mixins";
@import "./mixins";

// postcss-bem-linter: define fab

.mdc-fab {
  @include mdc-fab-base_;
  @include mdc-fab-container-color(secondary);
  @include mdc-fab-icon-size(24px);
  @include mdc-fab-ink-color(on-secondary);
  @include mdc-states(on-secondary);
}

.mdc-fab--mini {
  @include mdc-fab--mini_;
}

.mdc-fab--extended {
  @include mdc-fab--extended_;
}

.mdc-fab__label {
  @include mdc-fab--label_;
}

.mdc-fab__icon {
  @include mdc-fab__icon_;
}

// Increase specificity for FAB icon styles that need to override styles defined for .material-icons
// (which is loaded separately so the order of CSS definitions is not guaranteed)
.mdc-fab .mdc-fab__icon {
  @include mdc-fab__icon-overrides_;
}

.mdc-fab--exited {
  @include mdc-fab--exited_;
}

// postcss-bem-linter: end
