﻿@import "Misc/Mixins";
@import "Misc/Distance";
@import "Misc/Table";

.maof-clearfix{
    @include clearfix;
}

.maof-list {
    &--reset {
        list-style: none;
        margin: 0;
        padding: 0;
    }
}
