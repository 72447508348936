﻿@import "Table/Mixins";
/// <reference path="../App" />

.maof-table {
    $table: &;
    @include maof-table;

    &-root {
        @include maof-table-section;
        @include maof-space(5 / 3, (top, bottom));

        &__buttons {
            :not(:last-child) {
                @include mdc-rtl-reflexive-box(margin, right, 12px);
            }
        }
    }

    &-search {
        .mdc-text-field {
            @include maof-table-search-field;
            margin-bottom: 0;
        }

        &__items {
            width: 100%;
            padding: 0;
            margin: 20px 0 0;
        }

        &__item {
            @include maof-table-search-item;
        }
    }

    &-container {
        display: block;
        overflow: auto;
    }

    &__head {
        display: table-header-group;
        white-space: nowrap;
    }

    &__body {
        display: table-row-group;
    }

    &__row {
        display: table-row;
        @include maof-table-row-states(black, maof-color(primary), ":not(#{$table}__head) > &");
    }

    &__cell {
        display: table-cell;
        vertical-align: middle;

        &:not(&--actions) {
            @include maof-pad(4 / 3);
            border-bottom: 1px solid rgba(0, 0, 0, .18);
        }

        &--actions {
            width: 0;

            .mdc-menu {
                @include mdc-elevation(3);
                position: fixed;
            }
        }

        @include mdc-typography-prop(font-size, subtitle2);

        &:first-child, &--multi-action:first-child + & {
            @include mdc-rtl-reflexive-box(border, right, 1px solid rgba(0, 0, 0, .18))
        }

        &#{&}--multi-action {
            @include maof-table-multi-action;
        }

        #{$table}__head & {
            text-decoration: none;
            color: rgba(0, 0, 0, .54);

            i {
                display: none;
                vertical-align: text-top;
                line-height: 16px;
                @include mdc-rtl-reflexive-box(margin, left, 8px);
            }

            &--activated {
                span {
                    @include maof-ink-color(dark);
                }

                i {
                    display: inline-block;
                }
            }
        }
    }

    &-foot {
        @include maof-space(5 / 3, top);
        @include maof-table-section;
    }

    &-pagination {
        @include mdc-typography(caption);
        color: rgba(0, 0, 0, .54);

        .mdc-select {
            @include maof-table-rows-select;
        }

        &__buttons {
            @include maof-table-pagination-buttons;
        }
    }
}
