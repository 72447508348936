//
// Copyright 2018 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/theme/mixins";

@mixin mdc-notched-outline-idle-color($color) {
  .mdc-notched-outline__idle {
    @include mdc-theme-prop(border-color, $color);
  }
}

@mixin mdc-notched-outline-color($color) {
  .mdc-notched-outline__path {
    @include mdc-theme-prop(stroke, $color);
  }
}

@mixin mdc-notched-outline-stroke-width($width) {
  .mdc-notched-outline__path {
    stroke-width: $width;
  }
}

@mixin mdc-notched-outline-corner-radius($radius) {
  border-radius: $radius;
}

@mixin mdc-notched-outline-idle-corner-radius($radius) {
  .mdc-notched-outline__idle {
    border-radius: $radius;
  }
}
