﻿/// <reference path="../App" />

.maof-panel {
    overflow: hidden;
    @include mdc-elevation(5, #888);
    @include maof-pad(4, (right, bottom, left));

    &__title {
        @extend .mdc-typography--headline6;

        @for $i from 1 through 6 {
            &#{$i} {
                @extend .mdc-typography--headline#{$i};
            }
        }
    }

    [class*="maof-panel__title"] {
        @include maof-space(5 / 3, (top, bottom));
    }
}
