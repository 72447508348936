//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "../functions";
@import "@material/theme/mixins";
@import "@material/typography/mixins";

// postcss-bem-linter: define text-field-helper-text

.mdc-text-field-helper-text {
  @include mdc-typography(caption);

  margin: 0;
  transition: mdc-text-field-transition(opacity);
  opacity: 0;
  will-change: opacity;

  // stylelint-disable plugin/selector-bem-pattern
  .mdc-text-field + & {
    margin-bottom: 8px;
  }

  // stylelint-enable plugin/selector-bem-pattern
}

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial;
}

// postcss-bem-linter: end
