//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/rtl/mixins";

@mixin mdc-slideable-drawer {
  height: 100%;
  transform: translateX(-107%);
  transform: translateX(calc(-100% - 20px));
  will-change: transform;

  @include mdc-rtl {
    transform: translateX(107%);
    transform: translateX(calc(100% + 20px));
  }
}

@mixin mdc-slideable-drawer-open {
  transform: none;
}
