//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/rtl/mixins";
@import "../variables";
@import "../mixins";

$mdc-permanent-drawer-width: 240px;

.mdc-drawer--permanent {
  @include mdc-drawer-base_;
  @include mdc-drawer-ink-color($mdc-permanent-drawer-ink-color);
  @include mdc-drawer-fill-color($mdc-permanent-drawer-fill-color);
  @include mdc-rtl-reflexive-box(border, right, 1px solid #e4e4e4);
  @include mdc-rtl-reflexive-position(left, 0);

  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  box-sizing: border-box;
  width: $mdc-permanent-drawer-width;
  overflow: hidden;

  &--floating {
    @include mdc-rtl-reflexive-box(border, right, none);

    background: none;
  }
}
