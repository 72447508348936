//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/animation/variables";
@import "../mixins";

@keyframes mdc-chip-entry {
  from {
    transform: scale(.8);
    opacity: .4;
  }

  to {
    transform: scale(1);
    opacity: 1;
  }
}

.mdc-chip-set {
  @include mdc-chip-set-spacing(8px);

  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box;
}

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms $mdc-animation-deceleration-curve-timing-function;
}
