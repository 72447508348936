//
// Copyright 2017 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/theme/variables";

$mdc-chip-border-radius-default: 16px;
$mdc-chip-fill-color-default: mix(mdc-theme-prop-value(on-surface), mdc-theme-prop-value(surface), 12%);
$mdc-chip-ink-color-default: rgba(mdc-theme-prop-value(on-surface), .87);
$mdc-chip-horizontal-padding: 12px;
$mdc-chip-vertical-padding: 7px;
$mdc-chip-height-default: 32px;

$mdc-chip-icon-color: mdc-theme-prop-value(on-surface);
$mdc-chip-icon-opacity: .54;
$mdc-chip-trailing-icon-hover-opacity: .62;
$mdc-chip-trailing-icon-focus-opacity: .87;
$mdc-chip-leading-icon-size: 20px;
$mdc-chip-trailing-icon-size: 18px;
$mdc-chip-leading-icon-delay: -50ms; // Speed up delay to bridge gap between leading icon and checkmark transition.
$mdc-chip-checkmark-with-leading-icon-delay: 80ms;

$mdc-chip-checkmark-animation-delay: 50ms;
$mdc-chip-checkmark-animation-duration: 150ms;
$mdc-chip-width-animation-duration: 150ms;
$mdc-chip-opacity-animation-duration: 75ms;
