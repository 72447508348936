//
// Copyright 2018 Google Inc. All Rights Reserved.
//
// Licensed under the Apache License, Version 2.0 (the "License");
// you may not use this file except in compliance with the License.
// You may obtain a copy of the License at
//
//      http://www.apache.org/licenses/LICENSE-2.0
//
// Unless required by applicable law or agreed to in writing, software
// distributed under the License is distributed on an "AS IS" BASIS,
// WITHOUT WARRANTIES OR CONDITIONS OF ANY KIND, either express or implied.
// See the License for the specific language governing permissions and
// limitations under the License.
//

@import "@material/base/mixins";
@import "@material/theme/mixins";
@import "./functions";
@import "./mixins";

// Line Ripple is intended for use by multiple components, but its styles should only be emitted once when bundled
@include mdc-base-emit-once("mdc-line-ripple") {
  // postcss-bem-linter: define line-ripple
  .mdc-line-ripple {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 2px;
    transform: scaleX(0);
    transition: mdc-line-ripple-transition-value(transform), mdc-line-ripple-transition-value(opacity);
    opacity: 0;
    z-index: 2;
  }

  .mdc-line-ripple--active {
    transform: scaleX(1);
    opacity: 1;
  }

  .mdc-line-ripple--deactivating {
    opacity: 0;
  }
}
