﻿@import "Theme/Mixins";

$mdc-theme-primary: maof-color(primary);
$mdc-theme-secondary: maof-color(secondary);
$mdc-theme-on-primary: maof-color(reset);
$mdc-theme-on-secondary: maof-color(reset);

@each $name, $color in map-keys($maof-colors) {
    .maof-ink-#{$name} {
        @include maof-ink-color($name);
    }

    .maof-fill-#{$name} {
        @include maof-fill-color($name);
    }
}