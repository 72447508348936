.maof-ink-primary {
  color: #ff8000; }

.maof-fill-primary {
  background-color: #ff8000; }

.maof-ink-secondary {
  color: #0d92df; }

.maof-fill-secondary {
  background-color: #0d92df; }

.maof-ink-reset {
  color: #fff; }

.maof-fill-reset {
  background-color: #fff; }

.maof-ink-light1 {
  color: #fafafa; }

.maof-fill-light1 {
  background-color: #fafafa; }

.maof-ink-light2 {
  color: #efefef; }

.maof-fill-light2 {
  background-color: #efefef; }

.maof-ink-light3 {
  color: #ececec; }

.maof-fill-light3 {
  background-color: #ececec; }

.maof-ink-neutral {
  color: #dcdcdc; }

.maof-fill-neutral {
  background-color: #dcdcdc; }

.maof-ink-medium1 {
  color: #7d7d7d; }

.maof-fill-medium1 {
  background-color: #7d7d7d; }

.maof-ink-medium2 {
  color: #727272; }

.maof-fill-medium2 {
  background-color: #727272; }

.maof-ink-dark {
  color: #212121; }

.maof-fill-dark {
  background-color: #212121; }

.mdc-elevation--z0 {
  box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z1 {
  box-shadow: 0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z2 {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z3 {
  box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z4 {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z5 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z6 {
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z7 {
  box-shadow: 0px 4px 5px -2px rgba(0, 0, 0, 0.2), 0px 7px 10px 1px rgba(0, 0, 0, 0.14), 0px 2px 16px 1px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z8 {
  box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z9 {
  box-shadow: 0px 5px 6px -3px rgba(0, 0, 0, 0.2), 0px 9px 12px 1px rgba(0, 0, 0, 0.14), 0px 3px 16px 2px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z10 {
  box-shadow: 0px 6px 6px -3px rgba(0, 0, 0, 0.2), 0px 10px 14px 1px rgba(0, 0, 0, 0.14), 0px 4px 18px 3px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z11 {
  box-shadow: 0px 6px 7px -4px rgba(0, 0, 0, 0.2), 0px 11px 15px 1px rgba(0, 0, 0, 0.14), 0px 4px 20px 3px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z12 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z13 {
  box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 13px 19px 2px rgba(0, 0, 0, 0.14), 0px 5px 24px 4px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z14 {
  box-shadow: 0px 7px 9px -4px rgba(0, 0, 0, 0.2), 0px 14px 21px 2px rgba(0, 0, 0, 0.14), 0px 5px 26px 4px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z15 {
  box-shadow: 0px 8px 9px -5px rgba(0, 0, 0, 0.2), 0px 15px 22px 2px rgba(0, 0, 0, 0.14), 0px 6px 28px 5px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z16 {
  box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z17 {
  box-shadow: 0px 8px 11px -5px rgba(0, 0, 0, 0.2), 0px 17px 26px 2px rgba(0, 0, 0, 0.14), 0px 6px 32px 5px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z18 {
  box-shadow: 0px 9px 11px -5px rgba(0, 0, 0, 0.2), 0px 18px 28px 2px rgba(0, 0, 0, 0.14), 0px 7px 34px 6px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z19 {
  box-shadow: 0px 9px 12px -6px rgba(0, 0, 0, 0.2), 0px 19px 29px 2px rgba(0, 0, 0, 0.14), 0px 7px 36px 6px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z20 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 20px 31px 3px rgba(0, 0, 0, 0.14), 0px 8px 38px 7px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z21 {
  box-shadow: 0px 10px 13px -6px rgba(0, 0, 0, 0.2), 0px 21px 33px 3px rgba(0, 0, 0, 0.14), 0px 8px 40px 7px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z22 {
  box-shadow: 0px 10px 14px -6px rgba(0, 0, 0, 0.2), 0px 22px 35px 3px rgba(0, 0, 0, 0.14), 0px 8px 42px 7px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z23 {
  box-shadow: 0px 11px 14px -7px rgba(0, 0, 0, 0.2), 0px 23px 36px 3px rgba(0, 0, 0, 0.14), 0px 9px 44px 8px rgba(0, 0, 0, 0.12); }

.mdc-elevation--z24 {
  box-shadow: 0px 11px 15px -7px rgba(0, 0, 0, 0.2), 0px 24px 38px 3px rgba(0, 0, 0, 0.14), 0px 9px 46px 8px rgba(0, 0, 0, 0.12); }

.mdc-elevation-transition {
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1);
  will-change: box-shadow; }

@keyframes mdc-ripple-fg-radius-in {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 0.2, 1);
    transform: translate(var(--mdc-ripple-fg-translate-start, 0)) scale(1); }
  to {
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); } }

@keyframes mdc-ripple-fg-opacity-in {
  from {
    animation-timing-function: linear;
    opacity: 0; }
  to {
    opacity: var(--mdc-ripple-fg-opacity, 0); } }

@keyframes mdc-ripple-fg-opacity-out {
  from {
    animation-timing-function: linear;
    opacity: var(--mdc-ripple-fg-opacity, 0); }
  to {
    opacity: 0; } }

.mdc-ripple-surface--test-edge-var-bug {
  --mdc-ripple-surface-test-edge-var: 1px solid #000;
  visibility: hidden; }
  .mdc-ripple-surface--test-edge-var-bug::before {
    border: var(--mdc-ripple-surface-test-edge-var); }

.mdc-ripple-surface {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  position: relative;
  outline: none;
  overflow: hidden; }
  .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-ripple-surface::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-ripple-surface.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-ripple-surface.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-ripple-surface.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-ripple-surface.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-ripple-surface.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    background-color: black; }
  .mdc-ripple-surface:hover::before {
    opacity: 0.04; }
  .mdc-ripple-surface:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-ripple-surface:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-ripple-surface.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-ripple-surface::before, .mdc-ripple-surface::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-ripple-surface.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-ripple-surface[data-mdc-ripple-is-unbounded] {
    overflow: visible; }
    .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded]::after {
      top: calc(50% - 50%);
      /* @noflip */
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::before, .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      /* @noflip */
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-ripple-surface[data-mdc-ripple-is-unbounded].mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
    background-color: #ff8000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-ripple-surface--primary::before, .mdc-ripple-surface--primary::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #ff8000); } }
  .mdc-ripple-surface--primary:hover::before {
    opacity: 0.08; }
  .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--primary.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-ripple-surface--primary:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-ripple-surface--primary.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }
  .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
    background-color: #0d92df; }
    @supports not (-ms-ime-align: auto) {
      .mdc-ripple-surface--accent::before, .mdc-ripple-surface--accent::after {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #0d92df); } }
  .mdc-ripple-surface--accent:hover::before {
    opacity: 0.04; }
  .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):focus::before, .mdc-ripple-surface--accent.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-ripple-surface--accent:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-ripple-surface--accent.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-typography {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

.mdc-typography--headline1, .maof-panel__title1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 6rem;
  line-height: 6rem;
  font-weight: 300;
  letter-spacing: -0.01563em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline2, .maof-panel__title2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3.75rem;
  line-height: 3.75rem;
  font-weight: 300;
  letter-spacing: -0.00833em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline3, .maof-panel__title3 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 3rem;
  line-height: 3.125rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline4, .maof-panel__title4 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 2.125rem;
  line-height: 2.5rem;
  font-weight: 400;
  letter-spacing: 0.00735em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline5, .maof-panel__title5 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 400;
  letter-spacing: normal;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--headline6, .maof-panel__title, .maof-panel__title6 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1.25rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.0125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--subtitle2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.375rem;
  font-weight: 500;
  letter-spacing: 0.00714em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body1 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.5rem;
  font-weight: 400;
  letter-spacing: 0.03125em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--body2 {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--caption {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-typography--button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-typography--overline {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 2rem;
  font-weight: 500;
  letter-spacing: 0.16667em;
  text-decoration: none;
  text-transform: uppercase; }

.mdc-icon-button {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  width: 48px;
  height: 48px;
  padding: 12px;
  font-size: 24px;
  display: inline-block;
  position: relative;
  box-sizing: border-box;
  border: none;
  outline: none;
  background-color: transparent;
  fill: currentColor;
  color: inherit;
  text-decoration: none;
  cursor: pointer;
  user-select: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-icon-button::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-icon-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-icon-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-icon-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-icon-button::before, .mdc-icon-button::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-icon-button.mdc-ripple-upgraded::before, .mdc-icon-button.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-icon-button svg,
  .mdc-icon-button img {
    width: 24px;
    height: 24px; }
  .mdc-icon-button:disabled {
    color: rgba(0, 0, 0, 0.38);
    /* @alternate */
    color: var(--mdc-theme-text-disabled-on-light, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
  .mdc-icon-button::before, .mdc-icon-button::after {
    background-color: black; }
  .mdc-icon-button:hover::before {
    opacity: 0.04; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):focus::before, .mdc-icon-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-icon-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-icon-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-icon-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }

.mdc-fab {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 6px 10px 0px rgba(0, 0, 0, 0.14), 0px 1px 18px 0px rgba(0, 0, 0, 0.12);
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 56px;
  height: 56px;
  padding: 0;
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1), opacity 15ms linear 30ms, transform 270ms 0ms cubic-bezier(0, 0, 0.2, 1);
  border: none;
  border-radius: 50%;
  fill: currentColor;
  cursor: pointer;
  user-select: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  overflow: hidden;
  background-color: #0d92df;
  color: #fff;
  /* @alternate */
  color: var(--mdc-theme-on-secondary, #fff); }
  .mdc-fab::before, .mdc-fab::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-fab::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-fab.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-fab.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-fab.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-fab.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-fab.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-fab::before, .mdc-fab::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-fab.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-fab::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-fab:hover, .mdc-fab:focus {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-fab:active {
    box-shadow: 0px 7px 8px -4px rgba(0, 0, 0, 0.2), 0px 12px 17px 2px rgba(0, 0, 0, 0.14), 0px 5px 22px 4px rgba(0, 0, 0, 0.12); }
  .mdc-fab:active, .mdc-fab:focus {
    outline: none; }
  .mdc-fab:hover {
    cursor: pointer; }
  .mdc-fab > svg {
    width: 100%; }
  @supports not (-ms-ime-align: auto) {
    .mdc-fab {
      /* @alternate */
      background-color: var(--mdc-theme-secondary, #0d92df); } }
  .mdc-fab .mdc-fab__icon {
    width: 24px;
    height: 24px;
    font-size: 24px; }
  .mdc-fab::before, .mdc-fab::after {
    background-color: #fff; }
    @supports not (-ms-ime-align: auto) {
      .mdc-fab::before, .mdc-fab::after {
        /* @alternate */
        background-color: var(--mdc-theme-on-secondary, #fff); } }
  .mdc-fab:hover::before {
    opacity: 0.08; }
  .mdc-fab:not(.mdc-ripple-upgraded):focus::before, .mdc-fab.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-fab:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-fab:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-fab.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }

.mdc-fab--mini {
  width: 40px;
  height: 40px; }

.mdc-fab--extended {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  padding: 0 20px;
  width: auto;
  max-width: 100%;
  height: 48px;
  border-radius: 24px; }
  .mdc-fab--extended .mdc-fab__icon {
    /* @noflip */
    margin-left: -8px;
    /* @noflip */
    margin-right: 12px; }
    [dir="rtl"] .mdc-fab--extended .mdc-fab__icon, .mdc-fab--extended .mdc-fab__icon[dir="rtl"] {
      /* @noflip */
      margin-left: 12px;
      /* @noflip */
      margin-right: -8px; }
  .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon {
    /* @noflip */
    margin-left: 12px;
    /* @noflip */
    margin-right: -8px; }
    [dir="rtl"] .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon, .mdc-fab--extended .mdc-fab__label + .mdc-fab__icon[dir="rtl"] {
      /* @noflip */
      margin-left: -8px;
      /* @noflip */
      margin-right: 12px; }

.mdc-fab__label {
  justify-content: flex-start;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden; }

.mdc-fab__icon {
  transition: transform 180ms 90ms cubic-bezier(0, 0, 0.2, 1);
  fill: currentColor;
  will-change: transform; }

.mdc-fab .mdc-fab__icon {
  display: inline-flex;
  align-items: center;
  justify-content: center; }

.mdc-fab--exited {
  transform: scale(0);
  transition: opacity 15ms linear 150ms, transform 180ms 0ms cubic-bezier(0.4, 0, 1, 1);
  opacity: 0; }
  .mdc-fab--exited .mdc-fab__icon {
    transform: scale(0);
    transition: transform 135ms 0ms cubic-bezier(0.4, 0, 1, 1); }

.mdc-button {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 2.25rem;
  font-weight: 500;
  letter-spacing: 0.08929em;
  text-decoration: none;
  text-transform: uppercase;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  padding: 0 8px 0 8px;
  display: inline-flex;
  position: relative;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  min-width: 64px;
  height: 36px;
  border: none;
  outline: none;
  /* @alternate */
  line-height: inherit;
  user-select: none;
  -webkit-appearance: none;
  overflow: hidden;
  vertical-align: middle;
  border-radius: 2px; }
  .mdc-button::before, .mdc-button::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-button::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-button.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-button.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-button.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-button.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-button::before, .mdc-button::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-button.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-button::-moz-focus-inner {
    padding: 0;
    border: 0; }
  .mdc-button:active {
    outline: none; }
  .mdc-button:hover {
    cursor: pointer; }
  .mdc-button:disabled {
    background-color: transparent;
    color: rgba(0, 0, 0, 0.37);
    cursor: default;
    pointer-events: none; }
  .mdc-button:not(:disabled) {
    background-color: transparent; }
  .mdc-button:not(:disabled) {
    color: #ff8000;
    /* @alternate */
    color: var(--mdc-theme-primary, #ff8000); }
  .mdc-button::before, .mdc-button::after {
    background-color: #ff8000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button::before, .mdc-button::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #ff8000); } }
  .mdc-button:hover::before {
    opacity: 0.08; }
  .mdc-button:not(.mdc-ripple-upgraded):focus::before, .mdc-button.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-button:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-button.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }
  .mdc-button .mdc-button__icon {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 8px;
    display: inline-block;
    width: 18px;
    height: 18px;
    font-size: 18px;
    vertical-align: top; }
    [dir="rtl"] .mdc-button .mdc-button__icon, .mdc-button .mdc-button__icon[dir="rtl"] {
      /* @noflip */
      margin-left: 8px;
      /* @noflip */
      margin-right: 0; }
  .mdc-button svg.mdc-button__icon {
    fill: currentColor; }

.mdc-button--raised .mdc-button__icon,
.mdc-button--unelevated .mdc-button__icon,
.mdc-button--outlined .mdc-button__icon {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 8px; }
  [dir="rtl"] .mdc-button--raised .mdc-button__icon, .mdc-button--raised .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--unelevated .mdc-button__icon,
  .mdc-button--unelevated .mdc-button__icon[dir="rtl"], [dir="rtl"]
  .mdc-button--outlined .mdc-button__icon,
  .mdc-button--outlined .mdc-button__icon[dir="rtl"] {
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: -4px; }

.mdc-button--raised,
.mdc-button--unelevated {
  padding: 0 16px 0 16px; }
  .mdc-button--raised:disabled,
  .mdc-button--unelevated:disabled {
    background-color: rgba(0, 0, 0, 0.12);
    color: rgba(0, 0, 0, 0.37); }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    background-color: #ff8000; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised:not(:disabled),
      .mdc-button--unelevated:not(:disabled) {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #ff8000); } }
  .mdc-button--raised:not(:disabled),
  .mdc-button--unelevated:not(:disabled) {
    color: #fff;
    /* @alternate */
    color: var(--mdc-theme-on-primary, #fff); }
  .mdc-button--raised::before, .mdc-button--raised::after,
  .mdc-button--unelevated::before,
  .mdc-button--unelevated::after {
    background-color: #fff; }
    @supports not (-ms-ime-align: auto) {
      .mdc-button--raised::before, .mdc-button--raised::after,
      .mdc-button--unelevated::before,
      .mdc-button--unelevated::after {
        /* @alternate */
        background-color: var(--mdc-theme-on-primary, #fff); } }
  .mdc-button--raised:hover::before,
  .mdc-button--unelevated:hover::before {
    opacity: 0.08; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .mdc-button--raised.mdc-ripple-upgraded--background-focused::before,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before,
  .mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.24; }
  .mdc-button--raised:not(.mdc-ripple-upgraded)::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-button--raised:not(.mdc-ripple-upgraded):active::after,
  .mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.32; }
  .mdc-button--raised.mdc-ripple-upgraded,
  .mdc-button--unelevated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.32; }

.mdc-button--raised {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 280ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-button--raised:hover, .mdc-button--raised:focus {
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:active {
    box-shadow: 0px 5px 5px -3px rgba(0, 0, 0, 0.2), 0px 8px 10px 1px rgba(0, 0, 0, 0.14), 0px 3px 14px 2px rgba(0, 0, 0, 0.12); }
  .mdc-button--raised:disabled {
    box-shadow: 0px 0px 0px 0px rgba(0, 0, 0, 0.2), 0px 0px 0px 0px rgba(0, 0, 0, 0.14), 0px 0px 0px 0px rgba(0, 0, 0, 0.12); }

.mdc-button--outlined {
  border-style: solid;
  padding: 0 14px 0 14px;
  border-width: 2px; }
  .mdc-button--outlined:disabled {
    border-color: rgba(0, 0, 0, 0.37); }
  .mdc-button--outlined:not(:disabled) {
    border-color: #ff8000;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #ff8000); }

.mdc-button--dense {
  height: 32px;
  font-size: .8125rem; }

.mdc-list {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87));
  margin: 0;
  padding: 8px 0;
  /* @alternate */
  line-height: 1.5rem;
  list-style-type: none; }

.mdc-list-item__secondary-text {
  color: rgba(0, 0, 0, 0.54);
  /* @alternate */
  color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)); }

.mdc-list-item__graphic {
  background-color: transparent; }

.mdc-list-item__graphic {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-icon-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list-item__meta {
  color: rgba(0, 0, 0, 0.38);
  /* @alternate */
  color: var(--mdc-theme-text-hint-on-background, rgba(0, 0, 0, 0.38)); }

.mdc-list--dense {
  padding-top: 4px;
  padding-bottom: 4px;
  font-size: .812rem; }

.mdc-list-item {
  display: flex;
  position: relative;
  align-items: center;
  justify-content: flex-start;
  height: 48px;
  padding: 0 16px;
  overflow: hidden; }
  .mdc-list-item:focus {
    outline: none; }

.mdc-list-item--selected,
.mdc-list-item--activated {
  color: #ff8000;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff8000); }
  .mdc-list-item--selected .mdc-list-item__graphic,
  .mdc-list-item--activated .mdc-list-item__graphic {
    color: #ff8000;
    /* @alternate */
    color: var(--mdc-theme-primary, #ff8000); }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 32px;
  width: 24px;
  height: 24px;
  display: inline-flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: center; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 32px;
    /* @noflip */
    margin-right: 0; }

.mdc-list-item__meta {
  /* @noflip */
  margin-left: auto;
  /* @noflip */
  margin-right: 0; }
  .mdc-list-item[dir="rtl"] .mdc-list-item__meta,
  [dir="rtl"] .mdc-list-item .mdc-list-item__meta {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: auto; }

.mdc-list-item__text,
.mdc-list-item__secondary-text {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
  display: block; }

.mdc-list-item__secondary-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit; }

.mdc-list--dense .mdc-list-item__secondary-text {
  font-size: inherit; }

.mdc-list--dense .mdc-list-item {
  height: 40px; }

.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 36px;
  width: 20px;
  height: 20px; }
  .mdc-list-item[dir="rtl"] .mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 36px;
    /* @noflip */
    margin-right: 0; }

.mdc-list--avatar-list .mdc-list-item {
  height: 56px; }

.mdc-list--avatar-list .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px;
  width: 40px;
  height: 40px;
  border-radius: 50%; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }

:not(.mdc-list--non-interactive) > .mdc-list-item {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  :not(.mdc-list--non-interactive) > .mdc-list-item::before, :not(.mdc-list--non-interactive) > .mdc-list-item::after {
    background-color: black; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:hover::before {
    opacity: 0.04; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > .mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > .mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before {
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
    background-color: #ff8000; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > .mdc-list-item--activated::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #ff8000); } }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:hover::before {
    opacity: 0.32; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.48; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.56; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--activated.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.56; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before {
    opacity: 0.16; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
    background-color: #ff8000; }
    @supports not (-ms-ime-align: auto) {
      :not(.mdc-list--non-interactive) > .mdc-list-item--selected::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected::after {
        /* @alternate */
        background-color: var(--mdc-theme-primary, #ff8000); } }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:hover::before {
    opacity: 0.24; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):focus::before, :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.4; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.48; }
  :not(.mdc-list--non-interactive) > .mdc-list-item--selected.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.48; }

.mdc-list--two-line .mdc-list-item {
  height: 72px; }

.mdc-list--two-line.mdc-list--dense .mdc-list-item {
  height: 60px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item {
  height: 48px; }

.mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 20px;
  width: 36px;
  height: 36px; }
  .mdc-list-item[dir="rtl"] .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-item .mdc-list--avatar-list.mdc-list--dense .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 20px;
    /* @noflip */
    margin-right: 0; }

a.mdc-list-item {
  color: inherit;
  text-decoration: none; }

.mdc-list-divider {
  height: 0;
  margin: 0;
  border: none;
  border-bottom-width: 1px;
  border-bottom-style: solid; }

.mdc-list-divider {
  border-bottom-color: rgba(0, 0, 0, 0.12); }

.mdc-list-divider--padded {
  margin: 0 16px; }

.mdc-list-divider--inset {
  /* @noflip */
  margin-left: 72px;
  /* @noflip */
  margin-right: 0;
  width: calc(100% - 72px); }
  .mdc-list-group[dir="rtl"] .mdc-list-divider--inset,
  [dir="rtl"] .mdc-list-group .mdc-list-divider--inset {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 72px; }

.mdc-list-divider--inset.mdc-list-divider--padded {
  width: calc(100% - 72px - 16px); }

.mdc-list-group .mdc-list {
  padding: 0; }

.mdc-list-group__subheader {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0.75rem 16px; }

.mdc-list-group__subheader {
  color: rgba(0, 0, 0, 0.87);
  /* @alternate */
  color: var(--mdc-theme-text-primary-on-background, rgba(0, 0, 0, 0.87)); }

@keyframes mdc-checkbox-unchecked-checked-checkmark-path {
  0%,
  50% {
    stroke-dashoffset: 29.78334; }
  50% {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1); }
  100% {
    stroke-dashoffset: 0; } }

@keyframes mdc-checkbox-unchecked-indeterminate-mixedmark {
  0%,
  68.2% {
    transform: scaleX(0); }
  68.2% {
    animation-timing-function: cubic-bezier(0, 0, 0, 1); }
  100% {
    transform: scaleX(1); } }

@keyframes mdc-checkbox-checked-unchecked-checkmark-path {
  from {
    animation-timing-function: cubic-bezier(0.4, 0, 1, 1);
    opacity: 1;
    stroke-dashoffset: 0; }
  to {
    opacity: 0;
    stroke-dashoffset: -29.78334; } }

@keyframes mdc-checkbox-checked-indeterminate-checkmark {
  from {
    animation-timing-function: cubic-bezier(0, 0, 0.2, 1);
    transform: rotate(0deg);
    opacity: 1; }
  to {
    transform: rotate(45deg);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-checked-checkmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(45deg);
    opacity: 0; }
  to {
    transform: rotate(360deg);
    opacity: 1; } }

@keyframes mdc-checkbox-checked-indeterminate-mixedmark {
  from {
    animation-timing-function: mdc-animation-deceleration-curve-timing-function;
    transform: rotate(-45deg);
    opacity: 0; }
  to {
    transform: rotate(0deg);
    opacity: 1; } }

@keyframes mdc-checkbox-indeterminate-checked-mixedmark {
  from {
    animation-timing-function: cubic-bezier(0.14, 0, 0, 1);
    transform: rotate(0deg);
    opacity: 1; }
  to {
    transform: rotate(315deg);
    opacity: 0; } }

@keyframes mdc-checkbox-indeterminate-unchecked-mixedmark {
  0% {
    animation-timing-function: linear;
    transform: scaleX(1);
    opacity: 1; }
  32.8%,
  100% {
    transform: scaleX(0);
    opacity: 0; } }

.mdc-checkbox {
  display: inline-block;
  position: relative;
  flex: 0 0 18px;
  box-sizing: content-box;
  width: 18px;
  height: 18px;
  padding: 11px;
  line-height: 0;
  white-space: nowrap;
  cursor: pointer;
  vertical-align: bottom;
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-checkbox::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-checkbox.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-checkbox.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-checkbox.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-checkbox::before, .mdc-checkbox::after {
    background-color: #0d92df; }
    @supports not (-ms-ime-align: auto) {
      .mdc-checkbox::before, .mdc-checkbox::after {
        /* @alternate */
        background-color: var(--mdc-theme-secondary, #0d92df); } }
  .mdc-checkbox:hover::before {
    opacity: 0.04; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):focus::before, .mdc-checkbox.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-checkbox:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-checkbox:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-checkbox.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-checkbox::before, .mdc-checkbox::after {
    top: calc(50% - 50%);
    /* @noflip */
    left: calc(50% - 50%);
    width: 100%;
    height: 100%; }
  .mdc-checkbox.mdc-ripple-upgraded::before, .mdc-checkbox.mdc-ripple-upgraded::after {
    top: var(--mdc-ripple-top, calc(50% - 50%));
    /* @noflip */
    left: var(--mdc-ripple-left, calc(50% - 50%));
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-checkbox.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }

.mdc-checkbox__checkmark {
  color: #fff; }

.mdc-checkbox__mixedmark {
  border-color: #fff; }

.mdc-checkbox__background::before {
  background-color: #0d92df; }
  @supports not (-ms-ime-align: auto) {
    .mdc-checkbox__background::before {
      /* @alternate */
      background-color: var(--mdc-theme-secondary, #0d92df); } }

.mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.54);
  background-color: transparent; }

.mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
  border-color: #0d92df;
  /* @alternate */
  border-color: var(--mdc-theme-secondary, #0d92df);
  background-color: #0d92df;
  /* @alternate */
  background-color: var(--mdc-theme-secondary, #0d92df); }

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }
  50% {
    border-color: #0d92df;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #0d92df);
    background-color: #0d92df;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #0d92df); } }

@keyframes mdc-checkbox-fade-out-background-0 {
  0%, 80% {
    border-color: #0d92df;
    /* @alternate */
    border-color: var(--mdc-theme-secondary, #0d92df);
    background-color: #0d92df;
    /* @alternate */
    background-color: var(--mdc-theme-secondary, #0d92df); }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; } }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-in-background-0; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
  animation-name: mdc-checkbox-fade-out-background-0; }

.mdc-checkbox__native-control:disabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
  border-color: rgba(0, 0, 0, 0.26); }

.mdc-checkbox__native-control:disabled:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:disabled:indeterminate ~ .mdc-checkbox__background {
  border-color: transparent;
  background-color: rgba(0, 0, 0, 0.26); }

@media screen and (-ms-high-contrast: active) {
  .mdc-checkbox__mixedmark {
    margin: 0 1px; } }

.mdc-checkbox--disabled {
  cursor: default;
  pointer-events: none; }

.mdc-checkbox__background {
  /* @noflip */
  left: 11px;
  /* @noflip */
  right: initial;
  display: inline-flex;
  position: absolute;
  top: 11px;
  bottom: 0;
  align-items: center;
  justify-content: center;
  box-sizing: border-box;
  width: 45%;
  height: 45%;
  transition: background-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), border-color 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border: 2px solid currentColor;
  border-radius: 2px;
  background-color: transparent;
  pointer-events: none;
  will-change: background-color, border-color; }
  .mdc-checkbox[dir="rtl"] .mdc-checkbox__background,
  [dir="rtl"] .mdc-checkbox .mdc-checkbox__background {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 11px; }

.mdc-checkbox__checkmark {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  transition: opacity 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 0; }
  .mdc-checkbox--upgraded .mdc-checkbox__checkmark {
    opacity: 1; }

.mdc-checkbox__checkmark-path {
  transition: stroke-dashoffset 180ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke: currentColor;
  stroke-width: 3.12px;
  stroke-dashoffset: 29.78334;
  stroke-dasharray: 29.78334; }

.mdc-checkbox__mixedmark {
  width: 100%;
  height: 0;
  transform: scaleX(0) rotate(0deg);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border-width: 1px;
  border-style: solid;
  opacity: 0; }

.mdc-checkbox--upgraded .mdc-checkbox__background,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark,
.mdc-checkbox--upgraded .mdc-checkbox__checkmark-path,
.mdc-checkbox--upgraded .mdc-checkbox__mixedmark {
  transition: none !important; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__background, .mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__background, .mdc-checkbox--anim-checked-unchecked .mdc-checkbox__background, .mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__background {
  animation-duration: 180ms;
  animation-timing-function: linear; }

.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__checkmark-path {
  animation: 180ms linear 0s mdc-checkbox-unchecked-checked-checkmark-path;
  transition: none; }

.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__mixedmark {
  animation: 90ms linear 0s mdc-checkbox-unchecked-indeterminate-mixedmark;
  transition: none; }

.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__checkmark-path {
  animation: 90ms linear 0s mdc-checkbox-checked-unchecked-checkmark-path;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__checkmark {
  animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-checkmark;
  transition: none; }

.mdc-checkbox--anim-checked-indeterminate .mdc-checkbox__mixedmark {
  animation: 90ms linear 0s mdc-checkbox-checked-indeterminate-mixedmark;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__checkmark {
  animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-checkmark;
  transition: none; }

.mdc-checkbox--anim-indeterminate-checked .mdc-checkbox__mixedmark {
  animation: 500ms linear 0s mdc-checkbox-indeterminate-checked-mixedmark;
  transition: none; }

.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__mixedmark {
  animation: 300ms linear 0s mdc-checkbox-indeterminate-unchecked-mixedmark;
  transition: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background,
.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background {
  transition: border-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1), background-color 90ms 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path,
  .mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark-path {
    stroke-dashoffset: 0; }

.mdc-checkbox__background::before {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transform: scale(0, 0);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  border-radius: 50%;
  opacity: 0;
  pointer-events: none;
  content: "";
  will-change: opacity, transform; }

.mdc-ripple-upgraded--background-focused .mdc-checkbox__background::before {
  content: none; }

.mdc-checkbox__native-control:focus ~ .mdc-checkbox__background::before {
  transform: scale(2.75, 2.75);
  transition: opacity 80ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 80ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 0.12; }

.mdc-checkbox__native-control {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  margin: 0;
  padding: 0;
  opacity: 0;
  cursor: inherit; }
  .mdc-checkbox__native-control:disabled {
    cursor: default;
    pointer-events: none; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transition: opacity 180ms 0ms cubic-bezier(0, 0, 0.2, 1), transform 180ms 0ms cubic-bezier(0, 0, 0.2, 1);
  opacity: 1; }

.mdc-checkbox__native-control:checked ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(-45deg); }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__checkmark {
  transform: rotate(45deg);
  transition: opacity 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1), transform 90ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  opacity: 0; }

.mdc-checkbox__native-control:indeterminate ~ .mdc-checkbox__background .mdc-checkbox__mixedmark {
  transform: scaleX(1) rotate(0deg);
  opacity: 1; }

.mdc-notched-outline {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 1px);
  height: calc(100% - 2px);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  overflow: hidden; }
  .mdc-notched-outline svg {
    position: absolute;
    width: 100%;
    height: 100%; }

.mdc-notched-outline__idle {
  position: absolute;
  top: 0;
  left: 0;
  width: calc(100% - 4px);
  height: calc(100% - 4px);
  transition: opacity 150ms cubic-bezier(0.4, 0, 0.2, 1), border-color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  border: 1px solid;
  opacity: 1; }

.mdc-notched-outline__path {
  stroke-width: 1px;
  transition: stroke 150ms cubic-bezier(0.4, 0, 0.2, 1), stroke-width 150ms cubic-bezier(0.4, 0, 0.2, 1);
  fill: transparent; }

.mdc-notched-outline--notched {
  opacity: 1; }

.mdc-notched-outline--notched ~ .mdc-notched-outline__idle {
  opacity: 0; }

.mdc-floating-label {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  position: absolute;
  bottom: 8px;
  left: 0;
  transform-origin: left top;
  transition: transform 150ms cubic-bezier(0.4, 0, 0.2, 1), color 150ms cubic-bezier(0.4, 0, 0.2, 1);
  line-height: 1.15rem;
  text-overflow: ellipsis;
  white-space: nowrap;
  cursor: text;
  overflow: hidden;
  will-change: transform; }
  [dir="rtl"] .mdc-floating-label, .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    right: 0;
    /* @noflip */
    left: auto;
    /* @noflip */
    transform-origin: right top; }

.mdc-floating-label--float-above {
  cursor: auto; }

.mdc-floating-label--float-above {
  transform: translateY(-100%) scale(0.75); }

.mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-standard 250ms 1; }

@keyframes mdc-floating-label-shake-float-above-standard {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-100%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-100%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-100%) scale(0.75); } }

.mdc-line-ripple {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  transform: scaleX(0);
  transition: transform 180ms cubic-bezier(0.4, 0, 0.2, 1), opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  z-index: 2; }

.mdc-line-ripple--active {
  transform: scaleX(1);
  opacity: 1; }

.mdc-line-ripple--deactivating {
  opacity: 0; }

.mdc-text-field-helper-text {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.75rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.03333em;
  text-decoration: inherit;
  text-transform: inherit;
  margin: 0;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  opacity: 0;
  will-change: opacity; }
  .mdc-text-field + .mdc-text-field-helper-text {
    margin-bottom: 8px; }

.mdc-text-field-helper-text--persistent {
  transition: none;
  opacity: 1;
  will-change: initial; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  position: absolute;
  bottom: 16px;
  cursor: pointer; }

.mdc-text-field__icon:not([tabindex]),
.mdc-text-field__icon[tabindex="-1"] {
  cursor: default;
  pointer-events: none; }

.mdc-text-field {
  display: inline-block;
  position: relative;
  margin-bottom: 8px;
  will-change: opacity, transform, color; }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
    border-bottom-color: rgba(0, 0, 0, 0.42); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
    border-bottom-color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field .mdc-line-ripple {
    background-color: #ff8000;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #ff8000); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled) + .mdc-text-field-helper-text {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 0, 0, 0.12); }
  .mdc-text-field:not(.mdc-text-field--disabled) .mdc-text-field__icon {
    color: rgba(0, 0, 0, 0.54); }
  .mdc-text-field .mdc-text-field__input {
    caret-color: #ff8000;
    /* @alternate */
    caret-color: var(--mdc-theme-primary, #ff8000); }

.mdc-text-field__input {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 1rem;
  line-height: 1.75rem;
  font-weight: 400;
  letter-spacing: 0.00937em;
  text-decoration: inherit;
  text-transform: inherit;
  width: 100%;
  height: 30px;
  padding: 20px 0 1px;
  transition: opacity 180ms cubic-bezier(0.4, 0, 0.2, 1);
  border: none;
  border-bottom: 1px solid;
  border-radius: 0;
  background: none;
  appearance: none; }
  .mdc-text-field__input::placeholder {
    transition: color 180ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }
  .mdc-text-field__input:focus {
    outline: none; }
  .mdc-text-field__input:invalid {
    box-shadow: none; }
  .mdc-text-field__input:-webkit-autofill + .mdc-floating-label {
    transform: translateY(-100%) scale(0.75);
    cursor: auto; }

.mdc-text-field--outlined {
  height: 56px;
  border: none; }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
    border-color: rgba(0, 0, 0, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
    stroke: rgba(0, 0, 0, 0.24); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
    border-color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
  .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke: rgba(0, 0, 0, 0.87); }
  .mdc-text-field--outlined:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
    stroke: #ff8000;
    /* @alternate */
    stroke: var(--mdc-theme-primary, #ff8000); }
  .mdc-text-field--outlined .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-text-field--outlined .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-text-field--outlined .mdc-notched-outline {
    border-radius: 4px; }
  .mdc-text-field--outlined .mdc-notched-outline__idle {
    border-radius: 4px; }
  .mdc-text-field--outlined .mdc-text-field__input {
    display: flex;
    padding: 12px;
    border: none !important;
    background-color: transparent;
    z-index: 1; }
  .mdc-text-field--outlined .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    position: absolute;
    bottom: 20px; }
    [dir="rtl"] .mdc-text-field--outlined .mdc-floating-label, .mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
  .mdc-text-field--outlined .mdc-text-field__icon {
    z-index: 2; }

.mdc-text-field--outlined.mdc-text-field--focused .mdc-notched-outline__path {
  stroke-width: 2px; }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  color: rgba(0, 0, 0, 0.6); }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__idle {
  border-color: rgba(0, 0, 0, 0.06); }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-notched-outline__path {
  stroke: rgba(0, 0, 0, 0.06); }

.mdc-text-field--outlined.mdc-text-field--disabled .mdc-text-field__input {
  border-bottom: none; }

.mdc-text-field--outlined.mdc-text-field--dense {
  height: 48px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
    transform: translateY(-110%) scale(0.923); }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined-dense 250ms 1; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__input {
    padding: 12px 12px 7px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label {
    bottom: 16px; }
  .mdc-text-field--outlined.mdc-text-field--dense .mdc-text-field__icon {
    top: 12px; }

.mdc-text-field--box {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 4px 4px 0 0;
  display: inline-flex;
  position: relative;
  height: 56px;
  margin-top: 16px;
  overflow: hidden; }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-text-field--box::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-text-field--box.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field--box.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-text-field--box.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-text-field--box.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-text-field--box.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    background-color: rgba(0, 0, 0, 0.87); }
  .mdc-text-field--box:hover::before {
    opacity: 0.04; }
  .mdc-text-field--box:not(.mdc-ripple-upgraded):focus::before, .mdc-text-field--box.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-text-field--box::before, .mdc-text-field--box::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-text-field--box.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-text-field--box:not(.mdc-text-field--disabled) {
    background-color: whitesmoke; }
  .mdc-text-field--box .mdc-floating-label--float-above {
    transform: translateY(-50%) scale(0.75); }
  .mdc-text-field--box .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-box 250ms 1; }
  .mdc-text-field--box .mdc-text-field__input {
    align-self: flex-end;
    box-sizing: border-box;
    height: 100%;
    padding: 20px 16px 0; }
  .mdc-text-field--box .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    position: absolute;
    bottom: 20px;
    width: calc(100% - 48px);
    text-overflow: ellipsis;
    white-space: nowrap;
    pointer-events: none;
    overflow: hidden;
    will-change: transform; }
    [dir="rtl"] .mdc-text-field--box .mdc-floating-label, .mdc-text-field--box .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }

.mdc-text-field--box.mdc-text-field--disabled {
  background-color: #fafafa;
  border-bottom: none; }
  .mdc-text-field--box.mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(0, 0, 0, 0.06); }
  .mdc-text-field--box.mdc-text-field--disabled:not(.mdc-text-field--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--box.mdc-text-field--disabled:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.37); }

.mdc-text-field--box.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-70%) scale(0.923); }

.mdc-text-field--box.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-box-dense 250ms 1; }

.mdc-text-field--box.mdc-text-field--dense .mdc-text-field__input {
  padding: 12px 12px 0; }

.mdc-text-field--with-leading-icon .mdc-text-field__icon {
  /* @noflip */
  left: 15px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__icon, .mdc-text-field--with-leading-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 15px; }

.mdc-text-field--with-leading-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 48px;
  /* @noflip */
  padding-right: 15px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-text-field__input, .mdc-text-field--with-leading-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 15px;
    /* @noflip */
    padding-right: 48px; }

.mdc-text-field--with-leading-icon .mdc-floating-label {
  /* @noflip */
  left: 48px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon .mdc-floating-label, .mdc-text-field--with-leading-icon .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 48px; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above {
  transform: translateY(-130%) translateX(-32px) scale(0.75); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-130%) translateX(32px) scale(0.75); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl 250ms 1; }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above {
  transform: translateY(-110%) translateX(-21px) scale(0.923); }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--float-above[dir="rtl"] {
    transform: translateY(-110%) translateX(21px) scale(0.923); }

.mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense 250ms 1; }

[dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense .mdc-floating-label--shake, .mdc-text-field--with-leading-icon.mdc-text-field--outlined.mdc-text-field--dense[dir="rtl"] .mdc-floating-label--shake {
  animation: mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl 250ms 1; }

.mdc-text-field--with-trailing-icon .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 15px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__icon, .mdc-text-field--with-trailing-icon .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 15px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon .mdc-text-field__input {
  /* @noflip */
  padding-left: 15px;
  /* @noflip */
  padding-right: 48px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon .mdc-text-field__input, .mdc-text-field--with-trailing-icon .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 48px;
    /* @noflip */
    padding-right: 15px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon,
.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  bottom: 16px;
  transform: scale(0.8); }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: 12px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 12px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 38px;
  /* @noflip */
  padding-right: 12px; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 38px; }

.mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label {
  /* @noflip */
  left: 38px;
  /* @noflip */
  right: initial; }
  [dir="rtl"] .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label, .mdc-text-field--with-leading-icon.mdc-text-field--dense .mdc-floating-label[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 38px; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon {
  /* @noflip */
  left: initial;
  /* @noflip */
  right: 12px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__icon[dir="rtl"] {
    /* @noflip */
    left: 12px;
    /* @noflip */
    right: initial; }

.mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 38px; }
  [dir="rtl"] .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input, .mdc-text-field--with-trailing-icon.mdc-text-field--dense .mdc-text-field__input[dir="rtl"] {
    /* @noflip */
    padding-left: 38px;
    /* @noflip */
    padding-right: 12px; }

.mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
  display: inline-flex;
  position: relative;
  align-items: flex-end;
  box-sizing: border-box;
  margin-top: 16px; }
  .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box):not(.mdc-text-field--textarea):not(.mdc-text-field--outlined) {
    height: 48px; }

.mdc-text-field--dense {
  margin-top: 12px;
  margin-bottom: 4px; }
  .mdc-text-field--dense .mdc-floating-label--float-above {
    transform: translateY(-110%) scale(0.923); }
  .mdc-text-field--dense .mdc-floating-label {
    font-size: .813rem; }

.mdc-text-field__input:required + .mdc-floating-label::after {
  margin-left: 1px;
  content: "*"; }

.mdc-text-field--textarea {
  border-radius: 4px;
  display: flex;
  width: fit-content;
  height: initial;
  transition: none;
  border: 1px solid;
  overflow: hidden; }
  .mdc-text-field--textarea .mdc-floating-label {
    border-radius: 4px 4px 0 0; }
  .mdc-text-field--textarea .mdc-text-field__input {
    border-radius: 2px; }
  .mdc-text-field--textarea:not(.mdc-text-field--disabled) {
    border-color: rgba(0, 0, 0, 0.73); }
    .mdc-text-field--textarea:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
      border-color: rgba(0, 0, 0, 0.73); }
  .mdc-text-field--textarea .mdc-floating-label--float-above {
    transform: translateY(-50%) scale(0.923); }
  .mdc-text-field--textarea .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-textarea 250ms 1; }
  .mdc-text-field--textarea .mdc-text-field__input {
    height: auto;
    margin: 0;
    padding: 16px;
    padding-top: 32px;
    border: 1px solid transparent; }
  .mdc-text-field--textarea .mdc-floating-label {
    background-color: white;
    /* @noflip */
    left: 1px;
    /* @noflip */
    right: 0;
    /* @noflip */
    margin-left: 8px;
    /* @noflip */
    margin-right: 0;
    top: 18px;
    bottom: auto;
    margin-top: 2px;
    padding: 12px 8px 8px 8px;
    line-height: 1.15;
    pointer-events: none; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: 0;
      /* @noflip */
      right: 1px; }
    [dir="rtl"] .mdc-text-field--textarea .mdc-floating-label, .mdc-text-field--textarea .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 8px; }

.mdc-text-field--fullwidth {
  width: 100%; }
  .mdc-text-field--fullwidth .mdc-text-field__input {
    resize: vertical; }
  .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) {
    display: block;
    box-sizing: border-box;
    height: 56px;
    margin: 0;
    border: none;
    border-bottom: 1px solid;
    outline: none; }
    .mdc-text-field--fullwidth:not(.mdc-text-field--textarea) .mdc-text-field__input {
      width: 100%;
      height: 100%;
      padding: 0;
      resize: none;
      border: none !important; }

.mdc-text-field--fullwidth.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--textarea) {
  border-bottom-color: #b00020; }

.mdc-text-field--dense + .mdc-text-field-helper-text {
  margin-bottom: 4px; }

.mdc-text-field--box + .mdc-text-field-helper-text,
.mdc-text-field--outlined + .mdc-text-field-helper-text,
.mdc-text-field--textarea + .mdc-text-field-helper-text {
  margin-right: 16px;
  margin-left: 16px; }

.mdc-form-field > .mdc-text-field + label {
  align-self: flex-start; }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: rgba(255, 128, 0, 0.87); }

.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: rgba(255, 128, 0, 0.87); }

.mdc-text-field--focused .mdc-text-field__input:required + .mdc-floating-label::after {
  color: #b00020; }

.mdc-text-field--focused + .mdc-text-field-helper-text:not(.mdc-text-field-helper-text--validation-msg) {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) {
  border-color: #ff8000;
  /* @alternate */
  border-color: var(--mdc-theme-primary, #ff8000); }
  .mdc-text-field--textarea.mdc-text-field--focused:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
    border-color: #ff8000;
    /* @alternate */
    border-color: var(--mdc-theme-primary, #ff8000); }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
  border-bottom-color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input:hover {
  border-bottom-color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-line-ripple {
  background-color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-floating-label {
  color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input::placeholder {
  color: #b00020; }

.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  color: #b00020; }

.mdc-text-field--invalid .mdc-text-field__input {
  caret-color: #b00020; }

.mdc-text-field--invalid.mdc-text-field--with-trailing-icon:not(.mdc-text-field--disabled) .mdc-text-field__icon {
  color: #b00020; }

.mdc-text-field--invalid + .mdc-text-field-helper-text--validation-msg {
  opacity: 1; }

.mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) {
  border-color: #b00020; }
  .mdc-text-field--textarea.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-text-field__input:focus {
    border-color: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__idle {
  border-color: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled) .mdc-notched-outline__path {
  stroke: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
  border-color: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
  stroke: #b00020; }

.mdc-text-field--outlined.mdc-text-field--invalid:not(.mdc-text-field--disabled).mdc-text-field--focused .mdc-notched-outline__path {
  stroke: #b00020; }

.mdc-text-field--disabled {
  pointer-events: none; }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom-color: rgba(35, 31, 32, 0.26); }
  .mdc-text-field--disabled .mdc-text-field__input {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__input::placeholder {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled + .mdc-text-field-helper-text {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-text-field--disabled .mdc-text-field__icon {
    color: rgba(0, 0, 0, 0.3); }
  .mdc-text-field--disabled:not(.mdc-text-field--textarea) {
    border-bottom-color: rgba(0, 0, 0, 0.12); }
  .mdc-text-field--disabled .mdc-text-field__input {
    border-bottom: 1px dotted; }
  .mdc-text-field--disabled .mdc-floating-label {
    cursor: default; }

.mdc-text-field--textarea.mdc-text-field--disabled {
  border-color: rgba(35, 31, 32, 0.26);
  background-color: #f9f9f9;
  border-style: solid; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input:focus {
    border-color: rgba(35, 31, 32, 0.26); }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-text-field__input {
    border: 1px solid transparent; }
  .mdc-text-field--textarea.mdc-text-field--disabled .mdc-floating-label {
    background-color: #f9f9f9; }

@keyframes mdc-floating-label-shake-float-above-text-field-box {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-box-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-70%) scale(0.923); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-70%) scale(0.923); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-70%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-dense {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-110%) scale(0.923); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-110%) scale(0.923); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon {
  0% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 32px)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 32px)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - 32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense {
  0% {
    transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 21px)) translateY(-110%) scale(0.923); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 21px)) translateY(-110%) scale(0.923); }
  100% {
    transform: translateX(calc(0 - 21px)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-rtl {
  0% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - -32px)) translateY(-130%) scale(0.75); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - -32px)) translateY(-130%) scale(0.75); }
  100% {
    transform: translateX(calc(0 - -32px)) translateY(-130%) scale(0.75); } }

@keyframes mdc-floating-label-shake-float-above-text-field-outlined-leading-icon-dense-rtl {
  0% {
    transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - -21px)) translateY(-110%) scale(0.923); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - -21px)) translateY(-110%) scale(0.923); }
  100% {
    transform: translateX(calc(0 - -21px)) translateY(-110%) scale(0.923); } }

@keyframes mdc-floating-label-shake-float-above-textarea {
  0% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); }
  33% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(calc(4% - 0%)) translateY(-50%) scale(0.923); }
  66% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(calc(-4% - 0%)) translateY(-50%) scale(0.923); }
  100% {
    transform: translateX(calc(0 - 0%)) translateY(-50%) scale(0.923); } }

@keyframes mdc-select-float-native-control {
  0% {
    transform: translateY(8px);
    opacity: 0; }
  100% {
    transform: translateY(0);
    opacity: 1; } }

.mdc-select {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%230%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  display: inline-flex;
  position: relative;
  box-sizing: border-box;
  height: 52px;
  background-repeat: no-repeat;
  background-position: right 8px bottom 12px; }
  .mdc-select:not(.mdc-select--disabled) {
    background-color: transparent; }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-floating-label {
    color: rgba(0, 0, 0, 0.6); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
    border-bottom-color: rgba(0, 0, 0, 0.42); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-line-ripple {
    background-color: #ff8000;
    /* @alternate */
    background-color: var(--mdc-theme-primary, #ff8000); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-floating-label {
    color: rgba(255, 128, 0, 0.87); }
  .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control:hover {
    border-bottom-color: rgba(0, 0, 0, 0.87); }
  [dir="rtl"] .mdc-select, .mdc-select[dir="rtl"] {
    background-position: left 8px bottom 12px; }
  .mdc-select__native-control {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 26px;
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    width: 100%;
    padding-top: 20px;
    padding-bottom: 4px;
    border: none;
    border-bottom: 1px solid;
    border-radius: 0;
    outline: none;
    background-color: transparent;
    white-space: nowrap;
    cursor: pointer;
    appearance: none; }
    [dir="rtl"] .mdc-select__native-control, .mdc-select__native-control[dir="rtl"] {
      /* @noflip */
      padding-left: 26px;
      /* @noflip */
      padding-right: 0; }
    .mdc-select__native-control::-ms-expand {
      display: none; }

@-moz-document url-prefix("") {
  .mdc-select__native-control {
    text-indent: -2px; } }
  .mdc-select .mdc-floating-label {
    pointer-events: none; }
  .mdc-select .mdc-select__native-control:focus ~ .mdc-line-ripple::after {
    transform: scale(1, 2);
    opacity: 1; }

.mdc-select--box {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  height: 56px;
  border-radius: 4px 4px 0 0;
  background-position: right 10px center;
  overflow: hidden; }
  .mdc-select--box:not(.mdc-select--disabled) {
    background-color: whitesmoke; }
  .mdc-select--box::before, .mdc-select--box::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-select--box::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-select--box.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select--box.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-select--box.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-select--box.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-select--box.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-select--box::before, .mdc-select--box::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-select--box.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-select--box::before, .mdc-select--box::after {
    background-color: rgba(0, 0, 0, 0.87); }
  .mdc-select--box:hover::before {
    opacity: 0.04; }
  .mdc-select--box:not(.mdc-ripple-upgraded):focus::before, .mdc-select--box.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  [dir="rtl"] .mdc-select--box, .mdc-select--box[dir="rtl"] {
    background-position: left 10px center; }
  .mdc-select--box .mdc-select__native-control {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 26px;
    height: 56px;
    border-radius: 4px 4px 0 0; }
    [dir="rtl"] .mdc-select--box .mdc-select__native-control, .mdc-select--box .mdc-select__native-control[dir="rtl"] {
      /* @noflip */
      padding-left: 26px;
      /* @noflip */
      padding-right: 16px; }
  .mdc-select--box .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    bottom: 12px;
    line-height: 1.75rem; }
    [dir="rtl"] .mdc-select--box .mdc-floating-label, .mdc-select--box .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }
    .mdc-select--box .mdc-floating-label--float-above {
      transform: translateY(-40%) scale(0.75, 0.75); }

.mdc-select--outlined {
  height: 56px;
  border: none;
  background-position: right 10px center; }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__idle {
    border-color: rgba(0, 0, 0, 0.24); }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-notched-outline__path {
    stroke: rgba(0, 0, 0, 0.24); }
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select__native-control:focus) .mdc-select__native-control:hover ~ .mdc-notched-outline__idle {
    border-color: rgba(0, 0, 0, 0.87); }
  .mdc-select--outlined:not(.mdc-select--disabled):not(.mdc-select__native-control:focus) .mdc-select__native-control:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke: rgba(0, 0, 0, 0.87); }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke-width: 2px; }
  .mdc-select--outlined:not(.mdc-select--disabled) .mdc-select__native-control:focus ~ .mdc-notched-outline .mdc-notched-outline__path {
    stroke: #ff8000;
    /* @alternate */
    stroke: var(--mdc-theme-primary, #ff8000); }
  .mdc-select--outlined .mdc-floating-label--float-above {
    transform: translateY(-130%) scale(0.75); }
  .mdc-select--outlined .mdc-floating-label--shake {
    animation: mdc-floating-label-shake-float-above-text-field-outlined 250ms 1; }
  .mdc-select--outlined .mdc-notched-outline {
    border-radius: 4px; }
  .mdc-select--outlined .mdc-notched-outline__idle {
    border-radius: 4px; }
  [dir="rtl"] .mdc-select--outlined, .mdc-select--outlined[dir="rtl"] {
    background-position: left 10px center; }
  .mdc-select--outlined .mdc-select__native-control {
    /* @noflip */
    padding-left: 16px;
    /* @noflip */
    padding-right: 26px;
    display: flex;
    padding-top: 12px;
    padding-bottom: 12px;
    border: none;
    background-color: transparent;
    z-index: 1; }
    [dir="rtl"] .mdc-select--outlined .mdc-select__native-control, .mdc-select--outlined .mdc-select__native-control[dir="rtl"] {
      /* @noflip */
      padding-left: 26px;
      /* @noflip */
      padding-right: 16px; }
  .mdc-select--outlined .mdc-floating-label {
    /* @noflip */
    left: 16px;
    /* @noflip */
    right: initial;
    position: absolute;
    bottom: 20px; }
    [dir="rtl"] .mdc-select--outlined .mdc-floating-label, .mdc-select--outlined .mdc-floating-label[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 16px; }

.mdc-select--disabled {
  background-image: url("data:image/svg+xml,%3Csvg%20width%3D%2210px%22%20height%3D%225px%22%20viewBox%3D%227%2010%2010%205%22%20version%3D%221.1%22%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%20xmlns%3Axlink%3D%22http%3A%2F%2Fwww.w3.org%2F1999%2Fxlink%22%3E%0A%20%20%20%20%3Cpolygon%20id%3D%22Shape%22%20stroke%3D%22none%22%20fill%3D%22%23#000%22%20fill-rule%3D%22evenodd%22%20opacity%3D%220.54%22%20points%3D%227%2010%2012%2015%2017%2010%22%3E%3C%2Fpolygon%3E%0A%3C%2Fsvg%3E");
  opacity: .38;
  cursor: default;
  pointer-events: none; }
  .mdc-select--disabled .mdc-floating-label {
    color: rgba(0, 0, 0, 0.37); }
  .mdc-select--disabled .mdc-line-ripple {
    display: none; }
  .mdc-select--disabled .mdc-select__native-control {
    border-bottom-style: dotted; }
  .mdc-select--disabled.mdc-select--box {
    background-color: #fafafa; }
  .mdc-select--disabled.mdc-select--outlined .mdc-select__native-control {
    border-bottom-style: none; }
  .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__idle {
    border-color: rgba(0, 0, 0, 0.16); }
  .mdc-select--disabled.mdc-select--outlined .mdc-notched-outline__path {
    stroke: rgba(0, 0, 0, 0.16); }

.mdc-drawer--persistent {
  color: rgba(0, 0, 0, 0.87);
  width: 0; }
  .mdc-drawer--persistent .mdc-drawer__toolbar-spacer {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    box-sizing: border-box;
    height: 56px;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    @media (min-width: 600px) {
      .mdc-drawer--persistent .mdc-drawer__toolbar-spacer {
        height: 64px; } }
  .mdc-drawer--persistent .mdc-drawer__header {
    position: relative; }
  .mdc-drawer--persistent .mdc-drawer__header::before {
    display: block;
    padding-top: 56.25%;
    content: ""; }
  .mdc-drawer--persistent .mdc-drawer__header-content {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 16px; }
  .mdc-drawer--persistent .mdc-list-item {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    position: relative;
    outline: none;
    color: inherit;
    text-decoration: none; }
  .mdc-drawer--persistent .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.54); }
  .mdc-drawer--persistent.mdc-drawer--permanent,
  .mdc-drawer--persistent .mdc-drawer__drawer {
    background-color: #fff; }
  .mdc-drawer--persistent .mdc-drawer__drawer {
    /* @noflip */
    border-left: 0;
    /* @noflip */
    border-right: 1px solid #e4e4e4;
    /* @noflip */
    left: 0;
    /* @noflip */
    right: initial;
    height: 100%;
    transform: translateX(-107%);
    transform: translateX(calc(-100% - 20px));
    will-change: transform;
    display: inline-flex;
    flex-direction: column;
    box-sizing: border-box;
    width: 240px;
    overflow: hidden;
    touch-action: none; }
    [dir="rtl"] .mdc-drawer--persistent .mdc-drawer__drawer, .mdc-drawer--persistent .mdc-drawer__drawer[dir="rtl"] {
      /* @noflip */
      border-left: 1px solid #e4e4e4;
      /* @noflip */
      border-right: 0; }
    [dir="rtl"] .mdc-drawer--persistent .mdc-drawer__drawer, .mdc-drawer--persistent .mdc-drawer__drawer[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 0; }
    [dir="rtl"] .mdc-drawer--persistent .mdc-drawer__drawer, .mdc-drawer--persistent .mdc-drawer__drawer[dir="rtl"] {
      transform: translateX(107%);
      transform: translateX(calc(100% + 20px)); }
  .mdc-drawer--persistent.mdc-drawer--open {
    width: 240px;
    pointer-events: auto; }
    .mdc-drawer--persistent.mdc-drawer--open .mdc-drawer__drawer {
      transform: none; }
    [dir="rtl"] .mdc-drawer--persistent.mdc-drawer--open .mdc-drawer__drawer, .mdc-drawer--persistent.mdc-drawer--open[dir="rtl"] .mdc-drawer__drawer {
      transform: none; }
  .mdc-drawer--persistent.mdc-drawer--animating .mdc-drawer__drawer {
    transition: transform 200ms 0ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-drawer--persistent.mdc-drawer--animating.mdc-drawer--open .mdc-drawer__drawer {
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-drawer--permanent {
  color: rgba(0, 0, 0, 0.87);
  /* @noflip */
  border-left: 0;
  /* @noflip */
  border-right: 1px solid #e4e4e4;
  /* @noflip */
  left: 0;
  /* @noflip */
  right: initial;
  display: inline-flex;
  flex: 0 0 auto;
  flex-direction: column;
  box-sizing: border-box;
  width: 240px;
  overflow: hidden; }
  .mdc-drawer--permanent .mdc-drawer__toolbar-spacer {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    box-sizing: border-box;
    height: 56px;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    @media (min-width: 600px) {
      .mdc-drawer--permanent .mdc-drawer__toolbar-spacer {
        height: 64px; } }
  .mdc-drawer--permanent .mdc-drawer__header {
    position: relative; }
  .mdc-drawer--permanent .mdc-drawer__header::before {
    display: block;
    padding-top: 56.25%;
    content: ""; }
  .mdc-drawer--permanent .mdc-drawer__header-content {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 16px; }
  .mdc-drawer--permanent .mdc-list-item {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    position: relative;
    outline: none;
    color: inherit;
    text-decoration: none; }
  .mdc-drawer--permanent .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.54); }
  .mdc-drawer--permanent.mdc-drawer--permanent,
  .mdc-drawer--permanent .mdc-drawer__drawer {
    background-color: #fff; }
  [dir="rtl"] .mdc-drawer--permanent, .mdc-drawer--permanent[dir="rtl"] {
    /* @noflip */
    border-left: 1px solid #e4e4e4;
    /* @noflip */
    border-right: 0; }
  [dir="rtl"] .mdc-drawer--permanent, .mdc-drawer--permanent[dir="rtl"] {
    /* @noflip */
    left: initial;
    /* @noflip */
    right: 0; }
  .mdc-drawer--permanent--floating {
    /* @noflip */
    border-left: 0;
    /* @noflip */
    border-right: none;
    background: none; }
    [dir="rtl"] .mdc-drawer--permanent--floating, .mdc-drawer--permanent--floating[dir="rtl"] {
      /* @noflip */
      border-left: none;
      /* @noflip */
      border-right: 0; }

.mdc-drawer--temporary {
  color: rgba(0, 0, 0, 0.87);
  position: fixed;
  top: 0;
  left: 0;
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  pointer-events: none;
  overflow: hidden;
  contain: strict;
  z-index: 5; }
  .mdc-drawer--temporary .mdc-drawer__toolbar-spacer {
    display: flex;
    position: relative;
    flex-direction: row;
    flex-shrink: 0;
    align-items: center;
    box-sizing: border-box;
    height: 56px;
    padding: 16px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.12); }
    @media (min-width: 600px) {
      .mdc-drawer--temporary .mdc-drawer__toolbar-spacer {
        height: 64px; } }
  .mdc-drawer--temporary .mdc-drawer__header {
    position: relative; }
  .mdc-drawer--temporary .mdc-drawer__header::before {
    display: block;
    padding-top: 56.25%;
    content: ""; }
  .mdc-drawer--temporary .mdc-drawer__header-content {
    display: flex;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    align-items: flex-end;
    box-sizing: border-box;
    padding: 16px; }
  .mdc-drawer--temporary .mdc-list-item {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit;
    position: relative;
    outline: none;
    color: inherit;
    text-decoration: none; }
  .mdc-drawer--temporary .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.54); }
  .mdc-drawer--temporary.mdc-drawer--permanent,
  .mdc-drawer--temporary .mdc-drawer__drawer {
    background-color: #fff; }
  .mdc-drawer--temporary::before {
    background-color: rgba(0, 0, 0, 0.6); }
  .mdc-drawer--temporary::before {
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    box-sizing: border-box;
    width: 100%;
    height: 100%;
    opacity: 0;
    opacity: var(--mdc-temporary-drawer-opacity, 0);
    content: "";
    will-change: opacity; }
  .mdc-drawer--temporary .mdc-drawer__drawer {
    box-shadow: 0px 8px 10px -5px rgba(0, 0, 0, 0.2), 0px 16px 24px 2px rgba(0, 0, 0, 0.14), 0px 6px 30px 5px rgba(0, 0, 0, 0.12);
    /* @noflip */
    left: 0;
    /* @noflip */
    right: initial;
    height: 100%;
    transform: translateX(-107%);
    transform: translateX(calc(-100% - 20px));
    will-change: transform;
    display: flex;
    position: absolute;
    flex-direction: column;
    box-sizing: border-box;
    width: calc(100% - 56px);
    max-width: 280px;
    overflow: hidden;
    touch-action: none; }
    [dir="rtl"] .mdc-drawer--temporary .mdc-drawer__drawer, .mdc-drawer--temporary .mdc-drawer__drawer[dir="rtl"] {
      /* @noflip */
      left: initial;
      /* @noflip */
      right: 0; }
    [dir="rtl"] .mdc-drawer--temporary .mdc-drawer__drawer, .mdc-drawer--temporary .mdc-drawer__drawer[dir="rtl"] {
      transform: translateX(107%);
      transform: translateX(calc(100% + 20px)); }
    @media (min-width: 600px) {
      .mdc-drawer--temporary .mdc-drawer__drawer {
        width: calc(100% - 64px);
        max-width: 320px; } }
  .mdc-drawer--temporary .mdc-drawer__content {
    flex-grow: 1;
    box-sizing: border-box;
    margin: 0;
    overflow-x: hidden;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
    touch-action: pan-y; }
  .mdc-drawer--temporary .mdc-drawer__footer {
    box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
    flex-shrink: 0; }
  .mdc-drawer--temporary.mdc-drawer--open {
    pointer-events: auto; }
    .mdc-drawer--temporary.mdc-drawer--open::before {
      opacity: 1;
      opacity: var(--mdc-temporary-drawer-opacity, 1); }
    .mdc-drawer--temporary.mdc-drawer--open .mdc-drawer__drawer {
      transform: none; }
    [dir="rtl"] .mdc-drawer--temporary.mdc-drawer--open .mdc-drawer__drawer, .mdc-drawer--temporary.mdc-drawer--open[dir="rtl"] .mdc-drawer__drawer {
      transform: none; }
  .mdc-drawer--temporary.mdc-drawer--animating::before {
    transition: opacity 0.3s 0ms cubic-bezier(0, 0, 0.2, 1); }
  .mdc-drawer--temporary.mdc-drawer--animating .mdc-drawer__drawer {
    transition: transform 200ms 0ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-drawer--temporary.mdc-drawer--animating.mdc-drawer--open .mdc-drawer__drawer {
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-drawer-scroll-lock {
  overflow: hidden; }

.mdc-top-app-bar {
  background-color: #ff8000;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff8000);
  color: rgba(0, 0, 0, 0.87);
  display: flex;
  position: fixed;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  width: 100%;
  z-index: 4; }
  .mdc-top-app-bar .mdc-top-app-bar__action-item,
  .mdc-top-app-bar .mdc-top-app-bar__navigation-icon {
    color: #fff;
    /* @alternate */
    color: var(--mdc-theme-on-primary, #fff); }
    .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
      background-color: #fff; }
      @supports not (-ms-ime-align: auto) {
        .mdc-top-app-bar .mdc-top-app-bar__action-item::before, .mdc-top-app-bar .mdc-top-app-bar__action-item::after,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::before,
        .mdc-top-app-bar .mdc-top-app-bar__navigation-icon::after {
          /* @alternate */
          background-color: var(--mdc-theme-on-primary, #fff); } }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:hover::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:hover::before {
      opacity: 0.08; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.24; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.32; }
    .mdc-top-app-bar .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
    .mdc-top-app-bar .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.32; }
  .mdc-top-app-bar__row {
    display: flex;
    position: relative;
    box-sizing: border-box;
    width: 100%;
    height: 64px; }
  .mdc-top-app-bar__section {
    display: inline-flex;
    flex: 1 1 auto;
    align-items: center;
    min-width: 0;
    padding: 8px 12px;
    z-index: 1; }
    .mdc-top-app-bar__section--align-start {
      justify-content: flex-start;
      order: -1; }
    .mdc-top-app-bar__section--align-end {
      justify-content: flex-end;
      order: 1; }
  .mdc-top-app-bar__title {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1.25rem;
    line-height: 2rem;
    font-weight: 500;
    letter-spacing: 0.0125em;
    text-decoration: inherit;
    text-transform: inherit;
    /* @noflip */
    padding-left: 20px;
    /* @noflip */
    padding-right: 0;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    z-index: 1; }
    [dir="rtl"] .mdc-top-app-bar__title, .mdc-top-app-bar__title[dir="rtl"] {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 20px; }
  .mdc-top-app-bar__action-item, .mdc-top-app-bar__navigation-icon {
    --mdc-ripple-fg-size: 0;
    --mdc-ripple-left: 0;
    --mdc-ripple-top: 0;
    --mdc-ripple-fg-scale: 1;
    --mdc-ripple-fg-translate-end: 0;
    --mdc-ripple-fg-translate-start: 0;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    will-change: transform, opacity;
    display: flex;
    position: relative;
    flex-shrink: 0;
    align-items: center;
    justify-content: center;
    box-sizing: border-box;
    width: 48px;
    height: 48px;
    padding: 12px;
    border: none;
    outline: none;
    background-color: transparent;
    fill: currentColor;
    color: inherit;
    text-decoration: none;
    cursor: pointer; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      position: absolute;
      border-radius: 50%;
      opacity: 0;
      pointer-events: none;
      content: ""; }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__navigation-icon::before {
      transition: opacity 15ms linear;
      z-index: 1; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before {
      transform: scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: 0;
      /* @noflip */
      left: 0;
      transform: scale(0);
      transform-origin: center center; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--unbounded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--unbounded::after {
      top: var(--mdc-ripple-top, 0);
      /* @noflip */
      left: var(--mdc-ripple-left, 0); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-activation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-activation::after {
      animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded--foreground-deactivation::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--foreground-deactivation::after {
      animation: 150ms mdc-ripple-fg-opacity-out;
      transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
    .mdc-top-app-bar__action-item::before, .mdc-top-app-bar__action-item::after, .mdc-top-app-bar__navigation-icon::before, .mdc-top-app-bar__navigation-icon::after {
      top: calc(50% - 50%);
      /* @noflip */
      left: calc(50% - 50%);
      width: 100%;
      height: 100%; }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::before, .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::before, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      top: var(--mdc-ripple-top, calc(50% - 50%));
      /* @noflip */
      left: var(--mdc-ripple-left, calc(50% - 50%));
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .mdc-top-app-bar__action-item.mdc-ripple-upgraded::after, .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }

.mdc-top-app-bar--short {
  top: 0;
  right: auto;
  left: 0;
  width: 100%;
  transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
  [dir="rtl"] .mdc-top-app-bar--short, .mdc-top-app-bar--short[dir="rtl"] {
    right: 0;
    left: auto; }
  .mdc-top-app-bar--short .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short .mdc-top-app-bar__title {
    transition: opacity 200ms cubic-bezier(0.4, 0, 0.2, 1);
    opacity: 1; }

.mdc-top-app-bar--short-collapsed {
  /* @noflip */
  border-bottom-left-radius: 0;
  /* @noflip */
  border-bottom-right-radius: 4px;
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  width: 56px;
  transition: width 300ms cubic-bezier(0.4, 0, 0.2, 1); }
  [dir="rtl"] .mdc-top-app-bar--short-collapsed, .mdc-top-app-bar--short-collapsed[dir="rtl"] {
    /* @noflip */
    border-bottom-left-radius: 4px;
    /* @noflip */
    border-bottom-right-radius: 0; }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__title {
    display: none; }
  .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__action-item {
    transition: padding 150ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item {
  width: 112px; }
  .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }
    [dir="rtl"] .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed.mdc-top-app-bar--short-has-action-item .mdc-top-app-bar__section--align-end[dir="rtl"] {
      /* @noflip */
      padding-left: 12px;
      /* @noflip */
      padding-right: 0; }

.mdc-top-app-bar--dense .mdc-top-app-bar__row {
  height: 48px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__section {
  padding: 0 4px; }

.mdc-top-app-bar--dense .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 12px;
  /* @noflip */
  padding-right: 0; }
  [dir="rtl"] .mdc-top-app-bar--dense .mdc-top-app-bar__title, .mdc-top-app-bar--dense .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 12px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 128px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  align-self: flex-end;
  padding-bottom: 2px; }

.mdc-top-app-bar--prominent .mdc-top-app-bar__action-item,
.mdc-top-app-bar--prominent .mdc-top-app-bar__navigation-icon {
  align-self: flex-start; }

.mdc-top-app-bar--fixed {
  transition: box-shadow 200ms linear; }

.mdc-top-app-bar--fixed-scrolled {
  box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12);
  transition: box-shadow 200ms linear; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__row {
  height: 96px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__section {
  padding: 0 12px; }

.mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title {
  /* @noflip */
  padding-left: 20px;
  /* @noflip */
  padding-right: 0;
  padding-bottom: 9px; }
  [dir="rtl"] .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title, .mdc-top-app-bar--dense.mdc-top-app-bar--prominent .mdc-top-app-bar__title[dir="rtl"] {
    /* @noflip */
    padding-left: 0;
    /* @noflip */
    padding-right: 20px; }

.mdc-top-app-bar--fixed-adjust {
  padding-top: 64px; }

.mdc-top-app-bar--dense-fixed-adjust {
  padding-top: 48px; }

.mdc-top-app-bar--short-fixed-adjust {
  padding-top: 56px; }

.mdc-top-app-bar--prominent-fixed-adjust {
  padding-top: 128px; }

.mdc-top-app-bar--dense-prominent-fixed-adjust {
  padding-top: 96px; }

@media (max-width: 599px) {
  .mdc-top-app-bar__row {
    height: 56px; }
  .mdc-top-app-bar__section {
    padding: 4px; }
  .mdc-top-app-bar--short {
    transition: width 200ms cubic-bezier(0.4, 0, 0.2, 1); }
  .mdc-top-app-bar--short-collapsed {
    transition: width 250ms cubic-bezier(0.4, 0, 0.2, 1); }
    .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end {
      /* @noflip */
      padding-left: 0;
      /* @noflip */
      padding-right: 12px; }
      [dir="rtl"] .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end, .mdc-top-app-bar--short-collapsed .mdc-top-app-bar__section--align-end[dir="rtl"] {
        /* @noflip */
        padding-left: 12px;
        /* @noflip */
        padding-right: 0; }
  .mdc-top-app-bar--prominent .mdc-top-app-bar__title {
    padding-bottom: 6px; }
  .mdc-top-app-bar--fixed-adjust {
    padding-top: 56px; } }

.mdc-chip {
  --mdc-ripple-fg-size: 0;
  --mdc-ripple-left: 0;
  --mdc-ripple-top: 0;
  --mdc-ripple-fg-scale: 1;
  --mdc-ripple-fg-translate-end: 0;
  --mdc-ripple-fg-translate-start: 0;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  will-change: transform, opacity;
  border-radius: 16px;
  background-color: #e0e0e0;
  color: rgba(0, 0, 0, 0.87);
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased;
  font-size: 0.875rem;
  line-height: 1.25rem;
  font-weight: 400;
  letter-spacing: 0.01786em;
  text-decoration: inherit;
  text-transform: inherit;
  height: 32px;
  display: inline-flex;
  position: relative;
  align-items: center;
  box-sizing: border-box;
  padding: 7px 12px;
  outline: none;
  cursor: pointer;
  overflow: hidden; }
  .mdc-chip::before, .mdc-chip::after {
    position: absolute;
    border-radius: 50%;
    opacity: 0;
    pointer-events: none;
    content: ""; }
  .mdc-chip::before {
    transition: opacity 15ms linear;
    z-index: 1; }
  .mdc-chip.mdc-ripple-upgraded::before {
    transform: scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-chip.mdc-ripple-upgraded::after {
    top: 0;
    /* @noflip */
    left: 0;
    transform: scale(0);
    transform-origin: center center; }
  .mdc-chip.mdc-ripple-upgraded--unbounded::after {
    top: var(--mdc-ripple-top, 0);
    /* @noflip */
    left: var(--mdc-ripple-left, 0); }
  .mdc-chip.mdc-ripple-upgraded--foreground-activation::after {
    animation: 225ms mdc-ripple-fg-radius-in forwards, 75ms mdc-ripple-fg-opacity-in forwards; }
  .mdc-chip.mdc-ripple-upgraded--foreground-deactivation::after {
    animation: 150ms mdc-ripple-fg-opacity-out;
    transform: translate(var(--mdc-ripple-fg-translate-end, 0)) scale(var(--mdc-ripple-fg-scale, 1)); }
  .mdc-chip::before, .mdc-chip::after {
    top: calc(50% - 100%);
    /* @noflip */
    left: calc(50% - 100%);
    width: 200%;
    height: 200%; }
  .mdc-chip.mdc-ripple-upgraded::after {
    width: var(--mdc-ripple-fg-size, 100%);
    height: var(--mdc-ripple-fg-size, 100%); }
  .mdc-chip::before, .mdc-chip::after {
    background-color: rgba(0, 0, 0, 0.87); }
  .mdc-chip:hover::before {
    opacity: 0.04; }
  .mdc-chip:not(.mdc-ripple-upgraded):focus::before, .mdc-chip.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .mdc-chip:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .mdc-chip:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .mdc-chip.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .mdc-chip:hover {
    color: rgba(0, 0, 0, 0.87); }
  .mdc-chip .mdc-chip__icon.mdc-chip__icon--leading {
    color: rgba(0, 0, 0, 0.54); }
  .mdc-chip .mdc-chip__icon.mdc-chip__icon--trailing {
    color: rgba(0, 0, 0, 0.54); }
    .mdc-chip .mdc-chip__icon.mdc-chip__icon--trailing:hover {
      color: rgba(0, 0, 0, 0.62); }
    .mdc-chip .mdc-chip__icon.mdc-chip__icon--trailing:focus {
      color: rgba(0, 0, 0, 0.87); }
  .mdc-chip .mdc-chip__icon.mdc-chip__icon--leading:not(.mdc-chip__icon--leading-hidden) {
    width: 20px;
    height: 20px;
    font-size: 20px; }
  .mdc-chip .mdc-chip__icon.mdc-chip__icon--trailing {
    width: 18px;
    height: 18px;
    font-size: 18px; }
  .mdc-chip:hover {
    color: #000;
    /* @alternate */
    color: var(--mdc-theme-on-surface, #000); }

.mdc-chip--exit {
  transition: opacity 75ms cubic-bezier(0.4, 0, 0.2, 1), width 150ms cubic-bezier(0, 0, 0.2, 1), padding 100ms linear, margin 100ms linear;
  opacity: 0; }

.mdc-chip__text {
  white-space: nowrap; }

.mdc-chip__icon {
  border-radius: 50%;
  outline: none;
  vertical-align: middle; }

.mdc-chip__icon--trailing {
  margin: 0 -4px 0 4px; }

.mdc-chip__checkmark,
.mdc-chip__icon--leading {
  height: 20px;
  margin: -4px 4px -4px -4px; }

.mdc-chip__checkmark-path {
  transition: stroke-dashoffset 150ms 50ms cubic-bezier(0.4, 0, 0.6, 1);
  stroke-width: 2px;
  stroke-dashoffset: 29.78334;
  stroke-dasharray: 29.78334; }

.mdc-chip--selected .mdc-chip__checkmark-path {
  stroke-dashoffset: 0; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before {
  opacity: 0.16; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
  background-color: #ff8000; }
  @supports not (-ms-ime-align: auto) {
    .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected::after {
      /* @alternate */
      background-color: var(--mdc-theme-primary, #ff8000); } }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover::before {
  opacity: 0.24; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):focus::before, .mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.4; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.48; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.48; }

.mdc-chip-set--choice .mdc-chip.mdc-chip--selected {
  color: #ff8000;
  /* @alternate */
  color: var(--mdc-theme-primary, #ff8000); }
  .mdc-chip-set--choice .mdc-chip.mdc-chip--selected .mdc-chip__icon.mdc-chip__icon--leading {
    color: rgba(255, 128, 0, 0.54); }
  .mdc-chip-set--choice .mdc-chip.mdc-chip--selected:hover {
    color: #ff8000;
    /* @alternate */
    color: var(--mdc-theme-primary, #ff8000); }

.mdc-chip-set--choice .mdc-chip .mdc-chip__checkmark-path {
  stroke: #ff8000;
  /* @alternate */
  stroke: var(--mdc-theme-primary, #ff8000); }

.mdc-chip-set--choice .mdc-chip--selected {
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-surface, #fff); }

.mdc-chip__checkmark-svg {
  width: 0;
  height: 20px;
  transition: width 150ms cubic-bezier(0.4, 0, 0.2, 1); }

.mdc-chip--selected .mdc-chip__checkmark-svg {
  width: 20px; }

.mdc-chip-set--filter .mdc-chip__icon--leading {
  transition: opacity 75ms linear;
  transition-delay: -50ms;
  opacity: 1; }
  .mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark {
    transition: opacity 75ms linear;
    transition-delay: 80ms;
    opacity: 0; }
    .mdc-chip-set--filter .mdc-chip__icon--leading + .mdc-chip__checkmark .mdc-chip__checkmark-svg {
      transition: width 0ms; }

.mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading {
  opacity: 0; }
  .mdc-chip-set--filter .mdc-chip--selected .mdc-chip__icon--leading + .mdc-chip__checkmark {
    width: 0;
    opacity: 1; }

.mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading {
  width: 0;
  opacity: 0; }
  .mdc-chip-set--filter .mdc-chip__icon--leading-hidden.mdc-chip__icon--leading + .mdc-chip__checkmark {
    width: 20px; }

@keyframes mdc-chip-entry {
  from {
    transform: scale(0.8);
    opacity: .4; }
  to {
    transform: scale(1);
    opacity: 1; } }

.mdc-chip-set {
  padding: 4px;
  display: flex;
  flex-wrap: wrap;
  box-sizing: border-box; }
  .mdc-chip-set .mdc-chip {
    margin: 4px; }

.mdc-chip-set--input .mdc-chip {
  animation: mdc-chip-entry 100ms cubic-bezier(0, 0, 0.2, 1); }

.mdc-menu {
  box-shadow: 0px 3px 1px -2px rgba(0, 0, 0, 0.2), 0px 2px 2px 0px rgba(0, 0, 0, 0.14), 0px 1px 5px 0px rgba(0, 0, 0, 0.12);
  background-color: #fff;
  /* @alternate */
  background-color: var(--mdc-theme-background, #fff);
  display: none;
  position: absolute;
  box-sizing: border-box;
  min-width: 170px;
  max-width: calc(100vw - 32px);
  max-height: calc(100vh - 32px);
  margin: 0;
  padding: 0;
  transform: scale(1);
  transform-origin: top left;
  border-radius: 2px;
  opacity: 0;
  white-space: nowrap;
  overflow-x: hidden;
  overflow-y: auto;
  will-change: transform, opacity;
  z-index: 4; }
  .mdc-menu:focus {
    outline: none; }
  .mdc-menu--animating-open {
    display: inline-block;
    transform: scale(0.8);
    transition: opacity 0.03s linear, transform 0.12s cubic-bezier(0, 0, 0.2, 1);
    opacity: 0;
    overflow-y: hidden; }
  .mdc-menu--open {
    display: inline-block;
    transform: scale(1);
    opacity: 1; }
  .mdc-menu--animating-closed {
    display: inline-block;
    transition: opacity 0.075s linear;
    opacity: 0;
    overflow-y: hidden; }
  .mdc-menu__items {
    box-sizing: border-box;
    transform: scale(1);
    overflow-x: hidden;
    overflow-y: auto;
    will-change: transform; }
    .mdc-menu__items > .mdc-list-item {
      cursor: pointer; }
    .mdc-menu--animating .mdc-menu__items {
      overflow-y: hidden; }
  .mdc-menu--animating-open > .mdc-menu__items {
    transform: scale(1.25); }
  .mdc-menu--open > .mdc-menu__items {
    transform: scale(1); }
  [dir="rtl"] .mdc-menu {
    transform-origin: top right; }
  .mdc-menu .mdc-list-group,
  .mdc-menu .mdc-list {
    padding: 8px 0; }
  .mdc-menu .mdc-list-item {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 1rem;
    line-height: 1.75rem;
    font-weight: 400;
    letter-spacing: 0.00937em;
    text-decoration: inherit;
    text-transform: inherit;
    position: relative;
    outline: none;
    color: inherit;
    text-decoration: none;
    user-select: none; }
  .mdc-menu .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.54);
    /* @alternate */
    color: var(--mdc-theme-text-secondary-on-background, rgba(0, 0, 0, 0.54)); }
  .mdc-menu .mdc-list-item[aria-disabled="true"] {
    color: rgba(0, 0, 0, 0.38);
    /* @alternate */
    color: var(--mdc-theme-text-disabled-on-background, rgba(0, 0, 0, 0.38));
    cursor: default;
    pointer-events: none; }
    .mdc-menu .mdc-list-item[aria-disabled="true"]:focus::before {
      opacity: 0; }

.mdc-menu-anchor {
  position: relative;
  overflow: visible; }

@keyframes primary-indeterminate-translate {
  0% {
    transform: translateX(0); }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(0); }
  59.15% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(83.67142%); }
  100% {
    transform: translateX(200.61106%); } }

@keyframes primary-indeterminate-scale {
  0% {
    transform: scaleX(0.08); }
  36.65% {
    animation-timing-function: cubic-bezier(0.33473, 0.12482, 0.78584, 1);
    transform: scaleX(0.08); }
  69.15% {
    animation-timing-function: cubic-bezier(0.06, 0.11, 0.6, 1);
    transform: scaleX(0.66148); }
  100% {
    transform: scaleX(0.08); } }

@keyframes secondary-indeterminate-translate {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    transform: translateX(0); }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    transform: translateX(37.65191%); }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    transform: translateX(84.38617%); }
  100% {
    transform: translateX(160.27778%); } }

@keyframes secondary-indeterminate-scale {
  0% {
    animation-timing-function: cubic-bezier(0.20503, 0.05705, 0.57661, 0.45397);
    transform: scaleX(0.08); }
  19.15% {
    animation-timing-function: cubic-bezier(0.15231, 0.19643, 0.64837, 1.00432);
    transform: scaleX(0.4571); }
  44.15% {
    animation-timing-function: cubic-bezier(0.25776, -0.00316, 0.21176, 1.38179);
    transform: scaleX(0.72796); }
  100% {
    transform: scaleX(0.08); } }

@keyframes buffering {
  to {
    transform: translateX(-10px); } }

@keyframes primary-indeterminate-translate-reverse {
  0% {
    transform: translateX(0); }
  20% {
    animation-timing-function: cubic-bezier(0.5, 0, 0.70173, 0.49582);
    transform: translateX(0); }
  59.15% {
    animation-timing-function: cubic-bezier(0.30244, 0.38135, 0.55, 0.95635);
    transform: translateX(-83.67142%); }
  100% {
    transform: translateX(-200.61106%); } }

@keyframes secondary-indeterminate-translate-reverse {
  0% {
    animation-timing-function: cubic-bezier(0.15, 0, 0.51506, 0.40969);
    transform: translateX(0); }
  25% {
    animation-timing-function: cubic-bezier(0.31033, 0.28406, 0.8, 0.73371);
    transform: translateX(-37.65191%); }
  48.35% {
    animation-timing-function: cubic-bezier(0.4, 0.62704, 0.6, 0.90203);
    transform: translateX(-84.38617%); }
  100% {
    transform: translateX(-160.27778%); } }

@keyframes buffering-reverse {
  to {
    transform: translateX(10px); } }

.mdc-linear-progress {
  position: relative;
  width: 100%;
  height: 4px;
  transform: translateZ(0);
  transition: opacity 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1);
  overflow: hidden; }
  .mdc-linear-progress__bar {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: none;
    transform-origin: top left;
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-linear-progress__bar-inner {
    display: inline-block;
    position: absolute;
    width: 100%;
    height: 100%;
    animation: none; }
  .mdc-linear-progress__buffering-dots {
    position: absolute;
    width: 100%;
    height: 100%;
    animation: buffering 250ms infinite linear;
    background-repeat: repeat-x;
    background-size: 10px 4px; }
  .mdc-linear-progress__buffer {
    position: absolute;
    width: 100%;
    height: 100%;
    transform-origin: top left;
    transition: transform 250ms 0ms cubic-bezier(0.4, 0, 0.6, 1); }
  .mdc-linear-progress__primary-bar {
    transform: scaleX(0); }
  .mdc-linear-progress__secondary-bar {
    visibility: hidden; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__bar {
    transition: none; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar {
    left: -145.166611%;
    animation: primary-indeterminate-translate 2s infinite linear; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__primary-bar > .mdc-linear-progress__bar-inner {
      animation: primary-indeterminate-scale 2s infinite linear; }
  .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar {
    left: -54.888891%;
    animation: secondary-indeterminate-translate 2s infinite linear;
    visibility: visible; }
    .mdc-linear-progress--indeterminate .mdc-linear-progress__secondary-bar > .mdc-linear-progress__bar-inner {
      animation: secondary-indeterminate-scale 2s infinite linear; }
  .mdc-linear-progress--reversed .mdc-linear-progress__bar,
  .mdc-linear-progress--reversed .mdc-linear-progress__buffer {
    right: 0;
    transform-origin: center right; }
  .mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
    animation-name: primary-indeterminate-translate-reverse; }
  .mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
    animation-name: secondary-indeterminate-translate-reverse; }
  .mdc-linear-progress--reversed .mdc-linear-progress__buffering-dots {
    animation: buffering-reverse 250ms infinite linear; }
  .mdc-linear-progress--closed {
    opacity: 0; }

.mdc-linear-progress__bar-inner {
  background-color: #ff8000;
  /* @alternate */
  background-color: var(--mdc-theme-primary, #ff8000); }

.mdc-linear-progress__buffering-dots {
  background-image: url("data:image/svg+xml,%3Csvg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' x='0px' y='0px' enable-background='new 0 0 5 2' xml:space='preserve' viewBox='0 0 5 2' preserveAspectRatio='none slice'%3E%3Ccircle cx='1' cy='1' r='1' fill='%23e6e6e6'/%3E%3C/svg%3E"); }

.mdc-linear-progress__buffer {
  background-color: #e6e6e6; }

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__primary-bar {
  right: -145.166611%;
  left: auto; }

.mdc-linear-progress--indeterminate.mdc-linear-progress--reversed .mdc-linear-progress__secondary-bar {
  right: -54.888891%;
  left: auto; }

.fas, .far {
  font-size: 18px;
  letter-spacing: normal;
  text-transform: none;
  display: inline-block;
  white-space: nowrap;
  word-wrap: normal;
  text-align: center;
  line-height: 24px;
  vertical-align: middle; }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 33px; }
  .mdc-list-group__subheader[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list-group__subheader .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 33px;
    /* @noflip */
    margin-right: 0; }
  .mdc-list-item__graphic.fas, .mdc-list-item__graphic.far {
    line-height: 1.375rem; }

.mdc-chip__checkmark,
.mdc-chip__icon--leading {
  /* @noflip */
  margin-left: -4px;
  /* @noflip */
  margin-right: 4px; }
  [dir="rtl"] .mdc-chip__checkmark, .mdc-chip__checkmark[dir="rtl"], [dir="rtl"] .mdc-chip__icon--leading, .mdc-chip__icon--leading[dir="rtl"] {
    /* @noflip */
    margin-left: 4px;
    /* @noflip */
    margin-right: -4px; }

.mdc-list-item__graphic {
  /* @noflip */
  margin-left: 0;
  /* @noflip */
  margin-right: 16px; }
  .mdc-list--avatar-list .mdc-list-item[dir="rtl"] .mdc-list-item__graphic,
  [dir="rtl"] .mdc-list--avatar-list .mdc-list-item .mdc-list-item__graphic {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }

.maof-form--inline {
  display: inline-block; }

.maof-form--compact {
  width: 320px; }

.maof-form--double {
  width: 640px; }

.maof-form__row {
  display: table;
  table-layout: fixed;
  width: 100%;
  width: calc(100% + 16px);
  margin: 0 -8px; }
  .maof-form__row:not(:last-child) {
    margin-bottom: 16px; }
  .maof-form__row-group {
    margin-bottom: 16px; }
    .maof-form__row-group .maof-form__row:first-child .maof-form__field > .mdc-text-field--outlined {
      margin-top: 4px; }
    .maof-form__row-group-wrapper {
      overflow: hidden; }
      .maof-form__row-group-wrapper[initialized] .maof-form__row-group {
        overflow: hidden;
        transition: margin-top 235ms cubic-bezier(0.4, 0, 0.2, 1); }
  .maof-form__row .maof-form__field, .maof-form__row .maof-form__buttons {
    padding: 0 8px;
    display: table-cell;
    vertical-align: middle; }
  .maof-form__row .maof-form__field:not(:only-child) .mdc-text-field--outlined {
    top: 1px; }
    .maof-form__row .maof-form__field:not(:only-child) .mdc-text-field--outlined + .mdc-text-field-helper-text {
      top: 1px; }
  .maof-form__row .maof-form__buttons .mdc-button {
    bottom: 1px; }

.maof-form > .maof-form__buttons:last-child {
  margin-top: 16px;
  padding-top: 16px;
  border-top: 1px solid #dcdcdc; }

.maof-form__buttons .mdc-button {
  min-width: 100px; }
  .maof-form__buttons .mdc-button + .mdc-button {
    /* @noflip */
    margin-left: 16px;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] .maof-form__buttons .mdc-button + .mdc-button, .maof-form__buttons .mdc-button + .mdc-button[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 16px; }

.maof-form .mdc-notched-outline__idle {
  height: calc(100% - 5px); }

.maof-form__field > .mdc-select {
  width: 100%;
  /*+ .mdc-text-field-helper-text {
                color: $mdc-text-field-helper-text-color;
                margin: 0 12px;
            }*/ }
  .maof-form__field > .mdc-select--outlined .mdc-select__native-control {
    padding-bottom: 14px; }

.maof-form__field > .mdc-text-field {
  width: 100%;
  /*+ .mdc-text-field-helper-text {
                margin: 0 12px;
            }*/ }
  .maof-form__field > .mdc-text-field:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
    margin: 0; }
  .maof-form__field > .mdc-text-field--outlined .mdc-text-field__input {
    height: 32px;
    padding-bottom: 14px; }

.maof-form__field > .mdc-text-field-helper-text:last-child {
  color: rgba(0, 0, 0, 0.6);
  margin: 0 12px; }

.maof-form__field > .mdc-button {
  width: 100%;
  justify-content: space-between;
  flex-direction: row-reverse; }
  .maof-form__field > .mdc-button:not(:disabled) {
    color: #727272; }
  .maof-form__field > .mdc-button:not(:disabled) {
    background-color: #efefef; }
  .maof-form__field > .mdc-button .mdc-button__icon {
    flex: 1;
    margin: 0;
    text-align: right; }
    [dir="rtl"] .maof-form__field > .mdc-button .mdc-button__icon, .maof-form__field > .mdc-button .mdc-button__icon[dir="rtl"] {
      text-align: left; }

.maof-form .mdc-button::before, .maof-form .mdc-button::after {
  background-color: black; }

.maof-form .mdc-button:hover::before {
  opacity: 0.04; }

.maof-form .mdc-button:not(.mdc-ripple-upgraded):focus::before, .maof-form .mdc-button.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12; }

.maof-form .mdc-button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.maof-form .mdc-button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16; }

.maof-form .mdc-button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16; }

.maof-form .mdc-button:not(:disabled) {
  color: #727272; }

.maof-form .mdc-button--outlined::before, .maof-form .mdc-button--outlined::after {
  background-color: #0d92df; }

.maof-form .mdc-button--outlined:hover::before {
  opacity: 0.04; }

.maof-form .mdc-button--outlined:not(.mdc-ripple-upgraded):focus::before, .maof-form .mdc-button--outlined.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12; }

.maof-form .mdc-button--outlined:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.maof-form .mdc-button--outlined:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16; }

.maof-form .mdc-button--outlined.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16; }

.maof-form .mdc-button--outlined:not(:disabled) {
  color: #0d92df; }

.maof-form .mdc-button--outlined:not(:disabled) {
  border-color: #0d92df; }

.maof-form .mdc-button--raised::before, .maof-form .mdc-button--raised::after, .maof-form .mdc-button--unelevated::before, .maof-form .mdc-button--unelevated::after {
  background-color: #fff; }

.maof-form .mdc-button--raised:hover::before, .maof-form .mdc-button--unelevated:hover::before {
  opacity: 0.08; }

.maof-form .mdc-button--raised:not(.mdc-ripple-upgraded):focus::before, .maof-form .mdc-button--raised.mdc-ripple-upgraded--background-focused::before, .maof-form .mdc-button--unelevated:not(.mdc-ripple-upgraded):focus::before, .maof-form .mdc-button--unelevated.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.24; }

.maof-form .mdc-button--raised:not(.mdc-ripple-upgraded)::after, .maof-form .mdc-button--unelevated:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.maof-form .mdc-button--raised:not(.mdc-ripple-upgraded):active::after, .maof-form .mdc-button--unelevated:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.32; }

.maof-form .mdc-button--raised.mdc-ripple-upgraded, .maof-form .mdc-button--unelevated.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.32; }

.maof-form .mdc-button--raised:not(:disabled), .maof-form .mdc-button--unelevated:not(:disabled) {
  color: #fff; }

.maof-form .mdc-button--raised:not(:disabled), .maof-form .mdc-button--unelevated:not(:disabled) {
  background-color: #0d92df; }

.maof-form .maof-file-upload__button::before, .maof-form .maof-file-upload__button::after {
  background-color: black; }

.maof-form .maof-file-upload__button:hover::before {
  opacity: 0.04; }

.maof-form .maof-file-upload__button:not(.mdc-ripple-upgraded):focus::before, .maof-form .maof-file-upload__button.mdc-ripple-upgraded--background-focused::before {
  transition-duration: 75ms;
  opacity: 0.12; }

.maof-form .maof-file-upload__button:not(.mdc-ripple-upgraded)::after {
  transition: opacity 150ms linear; }

.maof-form .maof-file-upload__button:not(.mdc-ripple-upgraded):active::after {
  transition-duration: 75ms;
  opacity: 0.16; }

.maof-form .maof-file-upload__button.mdc-ripple-upgraded {
  --mdc-ripple-fg-opacity: 0.16; }

.maof-form .maof-file-upload__button:not(:disabled) {
  color: #727272; }

.maof-form .maof-file-upload__button:not(:disabled) {
  background-color: #efefef; }

.maof-form .maof-file-upload__placeholder {
  /* @noflip */
  margin-left: 16px;
  /* @noflip */
  margin-right: 0;
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.54); }
  [dir="rtl"] .maof-form .maof-file-upload__placeholder, .maof-form .maof-file-upload__placeholder[dir="rtl"] {
    /* @noflip */
    margin-left: 0;
    /* @noflip */
    margin-right: 16px; }

.maof-form .maof-file-upload.maof-form__field .mdc-text-field-helper-text:last-child {
  margin-top: 4px; }

/*.maof-space {
        @include maof-distance($distanceType);
    }*/
/*.maof-space.maof-space--third {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-space.maof-space--two-thirds {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-space.maof-space--four-thirds {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-space.maof-space--five-thirds {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-space.maof-space--double {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-space--vertical {
            @include maof-distance($distanceType, 1, $directions);
        }*/
/*.maof-space--vertical.maof-space--third {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--vertical.maof-space--two-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--vertical.maof-space--four-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--vertical.maof-space--five-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--vertical.maof-space--double {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--horizontal {
            @include maof-distance($distanceType, 1, $directions);
        }*/
/*.maof-space--horizontal.maof-space--third {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--horizontal.maof-space--two-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--horizontal.maof-space--four-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--horizontal.maof-space--five-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--horizontal.maof-space--double {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-space--top {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-space--top.maof-space--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--top.maof-space--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--top.maof-space--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--top.maof-space--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--top.maof-space--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--right {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-space--right.maof-space--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--right.maof-space--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--right.maof-space--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--right.maof-space--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--right.maof-space--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--bottom {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-space--bottom.maof-space--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--bottom.maof-space--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--bottom.maof-space--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--bottom.maof-space--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--bottom.maof-space--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--left {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-space--left.maof-space--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--left.maof-space--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--left.maof-space--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--left.maof-space--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-space--left.maof-space--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad {
        @include maof-distance($distanceType);
    }*/
/*.maof-pad.maof-pad--third {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-pad.maof-pad--two-thirds {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-pad.maof-pad--four-thirds {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-pad.maof-pad--five-thirds {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-pad.maof-pad--double {
            @include maof-distance($distanceType, $unitValue);
        }*/
/*.maof-pad--vertical {
            @include maof-distance($distanceType, 1, $directions);
        }*/
/*.maof-pad--vertical.maof-pad--third {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--vertical.maof-pad--two-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--vertical.maof-pad--four-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--vertical.maof-pad--five-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--vertical.maof-pad--double {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--horizontal {
            @include maof-distance($distanceType, 1, $directions);
        }*/
/*.maof-pad--horizontal.maof-pad--third {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--horizontal.maof-pad--two-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--horizontal.maof-pad--four-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--horizontal.maof-pad--five-thirds {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--horizontal.maof-pad--double {
                @include maof-distance($distanceType, $unitValue, $directions);
            }*/
/*.maof-pad--top {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-pad--top.maof-pad--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--top.maof-pad--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--top.maof-pad--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--top.maof-pad--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--top.maof-pad--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--right {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-pad--right.maof-pad--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--right.maof-pad--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--right.maof-pad--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--right.maof-pad--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--right.maof-pad--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--bottom {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-pad--bottom.maof-pad--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--bottom.maof-pad--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--bottom.maof-pad--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--bottom.maof-pad--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--bottom.maof-pad--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--left {
            @include maof-distance($distanceType, 1, $direction);
        }*/
/*.maof-pad--left.maof-pad--third {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--left.maof-pad--two-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--left.maof-pad--four-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--left.maof-pad--five-thirds {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
/*.maof-pad--left.maof-pad--double {
                @include maof-distance($distanceType, $unitValue, $direction);
            }*/
.maof-table {
  display: table; }
  .maof-table--fluid {
    width: 100%; }
  .maof-table--fixed {
    table-layout: fixed; }
  .maof-table-root {
    display: flex;
    flex-flow: row wrap;
    margin-top: 20px;
    margin-bottom: 20px; }
    .maof-table-root__section {
      display: inline-flex;
      flex: 1 1 auto;
      align-items: center; }
      .maof-table-root__section--align-start {
        justify-content: flex-start; }
      .maof-table-root__section--align-end {
        justify-content: flex-end; }
    .maof-table-root__buttons :not(:last-child) {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 12px; }
      [dir="rtl"] .maof-table-root__buttons :not(:last-child), .maof-table-root__buttons :not(:last-child)[dir="rtl"] {
        /* @noflip */
        margin-left: 12px;
        /* @noflip */
        margin-right: 0; }
  .maof-table-search .mdc-text-field {
    width: 100%;
    margin-bottom: 0; }
    .maof-table-search .mdc-text-field--upgraded:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
      margin: 0; }
    .maof-table-search .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline__idle,
    .maof-table-search .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline__idle {
      border-color: #7d7d7d; }
    .maof-table-search .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__input:hover ~ .mdc-notched-outline .mdc-notched-outline__path,
    .maof-table-search .mdc-text-field--outlined:not(.mdc-text-field--disabled):not(.mdc-text-field--focused) .mdc-text-field__icon:hover ~ .mdc-notched-outline .mdc-notched-outline__path {
      stroke: #7d7d7d; }
    .maof-table-search .mdc-text-field--outlined .mdc-notched-outline__idle {
      border-color: #dcdcdc; }
    .maof-table-search .mdc-text-field--outlined .mdc-notched-outline {
      border-radius: 28px; }
    .maof-table-search .mdc-text-field--outlined .mdc-notched-outline__idle {
      border-radius: 28px; }
    .maof-table-search .mdc-text-field--outlined .mdc-text-field__input {
      padding-right: 40px;
      padding-left: 40px; }
    .maof-table-search .mdc-text-field--outlined .mdc-floating-label {
      /* @noflip */
      left: 52px;
      /* @noflip */
      right: initial; }
      [dir="rtl"] .maof-table-search .mdc-text-field--outlined .mdc-floating-label, .maof-table-search .mdc-text-field--outlined .mdc-floating-label[dir="rtl"] {
        /* @noflip */
        left: initial;
        /* @noflip */
        right: 52px; }
    .maof-table-search .mdc-text-field--outlined .mdc-notched-outline {
      /*@include mdc-elevation(5, #888);*/ }
      .maof-table-search .mdc-text-field--outlined .mdc-notched-outline .mdc-notched-outline__path {
        stroke-width: 1.5px; }
      .maof-table-search .mdc-text-field--outlined .mdc-notched-outline__idle {
        box-shadow: 0px 3px 5px -1px rgba(136, 136, 136, 0.2), 0px 5px 8px 0px rgba(136, 136, 136, 0.14), 0px 1px 14px 0px rgba(136, 136, 136, 0.12); }
  .maof-table-search__items {
    width: 100%;
    padding: 0;
    margin: 20px 0 0; }
  .maof-table-search__item {
    /* @noflip */
    padding-left: 12px;
    /* @noflip */
    padding-right: 20px;
    color: rgba(0, 0, 0, 0.54);
    background-color: rgba(33, 33, 33, 0.08); }
    [dir="rtl"] .maof-table-search__item, .maof-table-search__item[dir="rtl"] {
      /* @noflip */
      padding-left: 20px;
      /* @noflip */
      padding-right: 12px; }
    .maof-table-search__item::before, .maof-table-search__item::after {
      background-color: rgba(0, 0, 0, 0.54); }
    .maof-table-search__item:hover::before {
      opacity: 0.04; }
    .maof-table-search__item:not(.mdc-ripple-upgraded):focus::before, .maof-table-search__item.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .maof-table-search__item:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .maof-table-search__item:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .maof-table-search__item.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    .maof-table-search__item:hover {
      color: rgba(0, 0, 0, 0.54); }
    .maof-table-search__item .mdc-chip__icon.mdc-chip__icon--leading {
      color: white; }
    .maof-table-search__item:first-child {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: 4px; }
      [dir="rtl"] .maof-table-search__item:first-child, .maof-table-search__item:first-child[dir="rtl"] {
        /* @noflip */
        margin-left: 4px;
        /* @noflip */
        margin-right: 0; }
    .maof-table-search__item .mdc-chip__icon {
      position: relative; }
      .maof-table-search__item .mdc-chip__icon::before {
        content: "";
        background-color: #7d7d7d;
        position: absolute;
        width: 100%;
        height: 100%;
        z-index: -1;
        border-radius: 100%;
        padding: 6px;
        left: -6px;
        top: -6px; }
      .maof-table-search__item .mdc-chip__icon--leading {
        /* @noflip */
        margin-left: -5px;
        /* @noflip */
        margin-right: 16px; }
        [dir="rtl"] .maof-table-search__item .mdc-chip__icon--leading, .maof-table-search__item .mdc-chip__icon--leading[dir="rtl"] {
          /* @noflip */
          margin-left: 16px;
          /* @noflip */
          margin-right: -5px; }
  .maof-table-container {
    display: block;
    overflow: auto; }
  .maof-table__head {
    display: table-header-group;
    white-space: nowrap; }
  .maof-table__body {
    display: table-row-group; }
  .maof-table__row {
    display: table-row; }
    :not(.maof-table__head) > .maof-table__row {
      cursor: pointer;
      transition: background-color 15ms linear, box-shadow 150ms linear; }
      :not(.maof-table__head) > .maof-table__row:hover, :not(.maof-table__head) > .maof-table__row.maof-table__row--open-actions {
        background-color: rgba(0, 0, 0, 0.04); }
      :not(.maof-table__head) > .maof-table__row:active:not(.maof-table__row--open-actions) {
        transition: background-color 15ms linear, box-shadow 75ms linear;
        box-shadow: 0 0 1000px rgba(0, 0, 0, 0.12) inset; }
      :not(.maof-table__head) > .maof-table__row.maof-table__row--activated {
        background-color: rgba(255, 128, 0, 0.16); }
        :not(.maof-table__head) > .maof-table__row.maof-table__row--activated:hover, :not(.maof-table__head) > .maof-table__row.maof-table__row--activated.maof-table__row--open-actions {
          background-color: rgba(255, 128, 0, 0.24); }
        :not(.maof-table__head) > .maof-table__row.maof-table__row--activated:active:not(.maof-table__row--open-actions) {
          box-shadow: 0 0 1000px rgba(255, 128, 0, 0.24) inset; }
  .maof-table__cell {
    display: table-cell;
    vertical-align: middle;
    font-size: 0.875rem; }
    .maof-table__cell:not(.maof-table__cell--actions) {
      padding: 16px;
      border-bottom: 1px solid rgba(0, 0, 0, 0.18); }
    .maof-table__cell--actions {
      width: 0; }
      .maof-table__cell--actions .mdc-menu {
        box-shadow: 0px 3px 3px -2px rgba(0, 0, 0, 0.2), 0px 3px 4px 0px rgba(0, 0, 0, 0.14), 0px 1px 8px 0px rgba(0, 0, 0, 0.12);
        position: fixed; }
    .maof-table__cell:first-child, .maof-table__cell--multi-action:first-child + .maof-table__cell {
      /* @noflip */
      border-left: 0;
      /* @noflip */
      border-right: 1px solid rgba(0, 0, 0, 0.18); }
      [dir="rtl"] .maof-table__cell:first-child, .maof-table__cell:first-child[dir="rtl"], [dir="rtl"] .maof-table__cell--multi-action:first-child + .maof-table__cell, .maof-table__cell--multi-action:first-child + .maof-table__cell[dir="rtl"] {
        /* @noflip */
        border-left: 1px solid rgba(0, 0, 0, 0.18);
        /* @noflip */
        border-right: 0; }
    .maof-table__cell.maof-table__cell--multi-action {
      width: 40px;
      /*position: relative;
    z-index: 1;*/
      padding-right: 4px;
      padding-left: 4px;
      padding-top: 0px;
      padding-bottom: 0px; }
      .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox {
        z-index: 1; }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox::before, .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox::after {
          background-color: #ff8000; }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox .mdc-checkbox__native-control:enabled:not(:checked):not(:indeterminate) ~ .mdc-checkbox__background {
          border-color: rgba(0, 0, 0, 0.54);
          background-color: transparent; }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox .mdc-checkbox__native-control:enabled:checked ~ .mdc-checkbox__background,
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox
        .mdc-checkbox__native-control:enabled:indeterminate ~ .mdc-checkbox__background {
          border-color: #ff8000;
          background-color: #ff8000; }

@keyframes mdc-checkbox-fade-in-background-0 {
  0% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; }
  50% {
    border-color: #ff8000;
    background-color: #ff8000; } }

@keyframes mdc-checkbox-fade-out-background-0 {
  0%,
  80% {
    border-color: #ff8000;
    background-color: #ff8000; }
  100% {
    border-color: rgba(0, 0, 0, 0.54);
    background-color: transparent; } }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox.mdc-checkbox--anim-unchecked-checked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox.mdc-checkbox--anim-unchecked-indeterminate .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
          animation-name: mdc-checkbox-fade-in-background-0; }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox.mdc-checkbox--anim-checked-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background, .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox.mdc-checkbox--anim-indeterminate-unchecked .mdc-checkbox__native-control:enabled ~ .mdc-checkbox__background {
          animation-name: mdc-checkbox-fade-out-background-0; }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox .mdc-checkbox__checkmark {
          color: #fff; }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox .mdc-checkbox__mixedmark {
          border-color: #fff; }
        .maof-table__cell.maof-table__cell--multi-action .mdc-checkbox .mdc-checkbox__background::before {
          background-color: #ff8000; }
    .maof-table__head .maof-table__cell {
      text-decoration: none;
      color: rgba(0, 0, 0, 0.54); }
      .maof-table__head .maof-table__cell i {
        display: none;
        vertical-align: text-top;
        line-height: 16px;
        /* @noflip */
        margin-left: 8px;
        /* @noflip */
        margin-right: 0; }
        [dir="rtl"] .maof-table__head .maof-table__cell i, .maof-table__head .maof-table__cell i[dir="rtl"] {
          /* @noflip */
          margin-left: 0;
          /* @noflip */
          margin-right: 8px; }
      .maof-table__head .maof-table__cell--activated span {
        color: #212121; }
      .maof-table__head .maof-table__cell--activated i {
        display: inline-block; }
  .maof-table-foot {
    margin-top: 20px;
    display: flex;
    flex-flow: row wrap; }
    .maof-table-foot__section {
      display: inline-flex;
      flex: 1 1 auto;
      align-items: center; }
      .maof-table-foot__section--align-start {
        justify-content: flex-start; }
      .maof-table-foot__section--align-end {
        justify-content: flex-end; }
  .maof-table-pagination {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.75rem;
    line-height: 1.25rem;
    font-weight: 400;
    letter-spacing: 0.03333em;
    text-decoration: inherit;
    text-transform: inherit;
    color: rgba(0, 0, 0, 0.54); }
    .maof-table-pagination .mdc-select {
      height: initial;
      background-position: right 2px bottom 5px;
      margin-right: 20px;
      margin-left: 20px; }
      .maof-table-pagination .mdc-select:not(.mdc-select--disabled) .mdc-select__native-control {
        color: rgba(0, 0, 0, 0.54); }
      [dir="rtl"] .maof-table-pagination .mdc-select, .maof-table-pagination .mdc-select[dir="rtl"] {
        background-position: left 2px bottom 5px; }
      .maof-table-pagination .mdc-select__native-control {
        font-family: Roboto, sans-serif;
        -moz-osx-font-smoothing: grayscale;
        -webkit-font-smoothing: antialiased;
        font-size: 0.75rem;
        line-height: 1.25rem;
        font-weight: 400;
        letter-spacing: 0.03333em;
        text-decoration: inherit;
        text-transform: inherit;
        padding-top: 0px;
        padding-bottom: 0px;
        line-height: initial;
        border-bottom: 0;
        /* @noflip */
        padding-left: 0;
        /* @noflip */
        padding-right: 14px; }
        [dir="rtl"] .maof-table-pagination .mdc-select__native-control, .maof-table-pagination .mdc-select__native-control[dir="rtl"] {
          /* @noflip */
          padding-left: 14px;
          /* @noflip */
          padding-right: 0; }
    .maof-table-pagination__buttons {
      /* @noflip */
      margin-left: 40px;
      /* @noflip */
      margin-right: 0; }
      [dir="rtl"] .maof-table-pagination__buttons, .maof-table-pagination__buttons[dir="rtl"] {
        /* @noflip */
        margin-left: 0;
        /* @noflip */
        margin-right: 40px; }
      .maof-table-pagination__buttons a {
        color: inherit;
        text-decoration: none;
        width: 17px;
        display: inline-block; }
        .maof-table-pagination__buttons a i {
          vertical-align: middle; }
          [dir="rtl"] .maof-table-pagination__buttons a i, .maof-table-pagination__buttons a i[dir="rtl"] {
            transform: rotateZ(180deg); }
        .maof-table-pagination__buttons a + a {
          /* @noflip */
          margin-left: 20px;
          /* @noflip */
          margin-right: 0; }
          [dir="rtl"] .maof-table-pagination__buttons a + a, .maof-table-pagination__buttons a + a[dir="rtl"] {
            /* @noflip */
            margin-left: 0;
            /* @noflip */
            margin-right: 20px; }

.maof-clearfix {
  zoom: 1;
  /* For IE 6/7 (trigger hasLayout) */ }
  .maof-clearfix::before, .maof-clearfix::after {
    content: "";
    display: table; }
  .maof-clearfix::after {
    clear: both; }

.maof-list--reset {
  list-style: none;
  margin: 0;
  padding: 0; }

.maof-header {
  color: #212121;
  background-color: #efefef; }
  .maof-header .mdc-top-app-bar__action-item,
  .maof-header .mdc-top-app-bar__navigation-icon {
    color: #212121; }
    .maof-header .mdc-top-app-bar__action-item::before, .maof-header .mdc-top-app-bar__action-item::after,
    .maof-header .mdc-top-app-bar__navigation-icon::before,
    .maof-header .mdc-top-app-bar__navigation-icon::after {
      background-color: #212121; }
    .maof-header .mdc-top-app-bar__action-item:hover::before,
    .maof-header .mdc-top-app-bar__navigation-icon:hover::before {
      opacity: 0.04; }
    .maof-header .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):focus::before, .maof-header .mdc-top-app-bar__action-item.mdc-ripple-upgraded--background-focused::before,
    .maof-header .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):focus::before,
    .maof-header .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .maof-header .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded)::after,
    .maof-header .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .maof-header .mdc-top-app-bar__action-item:not(.mdc-ripple-upgraded):active::after,
    .maof-header .mdc-top-app-bar__navigation-icon:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .maof-header .mdc-top-app-bar__action-item.mdc-ripple-upgraded,
    .maof-header .mdc-top-app-bar__navigation-icon.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
  .maof-header .mdc-top-app-bar__title {
    position: relative;
    overflow: visible;
    line-height: 1; }
    .maof-header .mdc-top-app-bar__title a {
      color: inherit;
      text-decoration: none; }
  .maof-header__breadcrumbs {
    margin: 5px 0 0;
    padding: 0;
    font-size: 12px;
    color: rgba(0, 0, 0, 0.54);
    list-style: none; }
    .maof-header__breadcrumbs li {
      display: inline-block; }
      .maof-header__breadcrumbs li a {
        vertical-align: middle; }
        .maof-header__breadcrumbs li a:hover {
          text-decoration: underline; }
      .maof-header__breadcrumbs li + li::before {
        content: "navigate_next";
        font-family: "Material Icons";
        font-weight: normal;
        font-style: normal;
        line-height: 1;
        letter-spacing: normal;
        text-transform: none;
        display: inline-block;
        -webkit-font-feature-settings: "liga";
        -webkit-font-smoothing: antialiased;
        vertical-align: middle; }
        [dir="rtl"] .maof-header__breadcrumbs li + li::before {
          content: "navigate_before"; }

.maof-sidenav {
  position: relative;
  z-index: 1;
  color: #212121; }
  .maof-sidenav .mdc-list-group__subheader {
    color: rgba(0, 0, 0, 0.54); }
  .maof-sidenav .mdc-list-item__graphic {
    color: rgba(0, 0, 0, 0.54); }
  .maof-sidenav.mdc-drawer--permanent,
  .maof-sidenav .mdc-drawer__drawer {
    background-color: #fafafa; }
  .maof-sidenav__wrapper {
    overflow: hidden; }
    .maof-sidenav:not([initialized]) .maof-sidenav__wrapper {
      height: 0; }
  .maof-sidenav[initialized] .maof-sidenav__submenu {
    transition: margin-top 235ms cubic-bezier(0.4, 0, 0.2, 1); }
  .maof-sidenav .mdc-list-group__subheader {
    font-family: Roboto, sans-serif;
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    font-size: 0.875rem;
    line-height: 1.375rem;
    font-weight: 500;
    letter-spacing: 0.00714em;
    text-decoration: inherit;
    text-transform: inherit; }
  .maof-sidenav .mdc-list-item__graphic {
    font-size: 14px; }
    .maof-sidenav .mdc-list-item__graphic svg {
      fill: rgba(0, 0, 0, 0.54); }
    .maof-sidenav .mdc-list-item__graphic[data-indicator] {
      transition: transform 235ms cubic-bezier(0.4, 0, 0.2, 1); }
      .maof-sidenav .mdc-list-item__graphic[data-indicator][data-indicator="closed"] {
        transform: rotateZ(90deg); }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu > .mdc-list-item--activated {
    color: #fff;
    background-color: #ff8000; }
    .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu > .mdc-list-item--activated::before, .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu > .mdc-list-item--activated::after {
      background-color: rgba(255, 255, 255, 0.5); }
    .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu > .mdc-list-item--activated svg {
      fill: #fff; }
    .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu > .mdc-list-item--activated i {
      color: #fff; }
    .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu > .mdc-list-item--activated:not(:hover):not(.mdc-ripple-upgraded--background-focused)::before {
      opacity: 0; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu > .maof-sidenav__wrapper > .maof-sidenav__submenu > .maof-sidenav__wrapper > .maof-sidenav__submenu > .mdc-list-item {
    /*&--activated {
                        @include maof-ink-color(primary);
                    }*/ }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item::before, .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item::after {
    background-color: black; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item:hover::before {
    opacity: 0.04; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item:not(.mdc-ripple-upgraded):focus::before, .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item.mdc-ripple-upgraded--background-focused::before {
    transition-duration: 75ms;
    opacity: 0.12; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item:not(.mdc-ripple-upgraded)::after {
    transition: opacity 150ms linear; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item:not(.mdc-ripple-upgraded):active::after {
    transition-duration: 75ms;
    opacity: 0.16; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item.mdc-ripple-upgraded {
    --mdc-ripple-fg-opacity: 0.16; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item--activated.maof-sidenav__bottom {
    color: #ff8000; }
  .maof-sidenav nav > .maof-sidenav__wrapper > .maof-sidenav__submenu .maof-sidenav__wrapper > .maof-sidenav__submenu .mdc-list-item--activated:not(:focus):not(:hover)::before {
    opacity: 0; }
  .maof-sidenav .mdc-drawer__drawer {
    /* @noflip */
    border-left: 0;
    /* @noflip */
    border-right: 0;
    box-shadow: 0px 3px 5px -1px rgba(0, 0, 0, 0.2), 0px 5px 8px 0px rgba(0, 0, 0, 0.14), 0px 1px 14px 0px rgba(0, 0, 0, 0.12); }
    [dir="rtl"] .maof-sidenav .mdc-drawer__drawer, .maof-sidenav .mdc-drawer__drawer[dir="rtl"] {
      /* @noflip */
      border-left: 0;
      /* @noflip */
      border-right: 0; }
  .maof-sidenav .mdc-drawer__content {
    overflow: auto;
    max-height: 100%; }

.maof-panel {
  overflow: hidden;
  box-shadow: 0px 3px 5px -1px rgba(136, 136, 136, 0.2), 0px 5px 8px 0px rgba(136, 136, 136, 0.14), 0px 1px 14px 0px rgba(136, 136, 136, 0.12);
  padding-right: 48px;
  padding-bottom: 48px;
  padding-left: 48px; }
  .maof-panel [class*="maof-panel__title"] {
    margin-top: 20px;
    margin-bottom: 20px; }

html, body, #elMainContainer {
  height: 100%; }

html {
  font-family: Roboto, sans-serif;
  -moz-osx-font-smoothing: grayscale;
  -webkit-font-smoothing: antialiased; }

body {
  color: #212121; }

#elMainContainer {
  box-sizing: border-box;
  display: flex; }

#elMainContent {
  position: relative;
  width: 100%;
  overflow: auto; }
  #elMainContent main {
    overflow: hidden;
    display: table;
    height: 100%;
    width: 100%;
    padding: 24px;
    box-sizing: border-box; }

/* Make clicks pass-through */
#nprogress {
  pointer-events: none; }

#nprogress .bar {
  background-color: #ff8000;
  position: fixed;
  z-index: 1031;
  top: 0;
  left: 0;
  width: 100%;
  height: 2px; }

/* Fancy blur effect */
#nprogress .peg {
  display: block;
  position: absolute;
  right: 0px;
  width: 100px;
  height: 100%;
  box-shadow: 0 0 10px #ff8000, 0 0 5px #ff8000;
  opacity: 1.0;
  -webkit-transform: rotate(3deg) translate(0px, -4px);
  -ms-transform: rotate(3deg) translate(0px, -4px);
  transform: rotate(3deg) translate(0px, -4px); }

/* Remove these to get rid of the spinner */
#nprogress .spinner {
  display: block;
  position: fixed;
  z-index: 1031;
  top: 15px;
  right: 15px; }

#nprogress .spinner-icon {
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px transparent;
  border-top-color: #ff8000;
  border-left-color: #ff8000;
  border-radius: 50%;
  -webkit-animation: nprogress-spinner 400ms linear infinite;
  animation: nprogress-spinner 400ms linear infinite; }

.nprogress-custom-parent {
  overflow: hidden;
  position: relative; }

.nprogress-custom-parent #nprogress .spinner,
.nprogress-custom-parent #nprogress .bar {
  position: absolute; }

@-webkit-keyframes nprogress-spinner {
  0% {
    -webkit-transform: rotate(0deg); }
  100% {
    -webkit-transform: rotate(360deg); } }

@keyframes nprogress-spinner {
  0% {
    transform: rotate(0deg); }
  100% {
    transform: rotate(360deg); } }

#elClassrooms {
  height: 100%;
  text-align: center;
  position: relative; }
  #elClassrooms > ul {
    height: 100%;
    display: table;
    table-layout: fixed;
    /* @noflip */
    margin-left: -24px;
    /* @noflip */
    margin-right: 0; }
    [dir="rtl"] #elClassrooms > ul, #elClassrooms > ul[dir="rtl"] {
      /* @noflip */
      margin-left: 0;
      /* @noflip */
      margin-right: -24px; }
    #elClassrooms > ul > li {
      height: 100%;
      display: table-cell;
      vertical-align: top; }
      #elClassrooms > ul > li:not(:last-child) {
        /* @noflip */
        border-left: 0;
        /* @noflip */
        border-right: 1px solid #dcdcdc; }
        [dir="rtl"] #elClassrooms > ul > li:not(:last-child), #elClassrooms > ul > li:not(:last-child)[dir="rtl"] {
          /* @noflip */
          border-left: 1px solid #dcdcdc;
          /* @noflip */
          border-right: 0; }
  #elClassrooms.maof-drag--active {
    pointer-events: none; }

.maof-classroom {
  padding: 0 24px;
  padding-top: 106px;
  box-sizing: border-box;
  width: 288px;
  height: 100%;
  position: relative;
  /* #region FABs */
  /* #endregion */ }
  .maof-classroom__title.maof-classroom__item {
    position: absolute;
    right: 24px;
    left: 24px;
    top: 0;
    margin-bottom: 0;
    padding-top: 14px;
    padding-bottom: 14px;
    box-shadow: 0px 3px 5px -1px rgba(136, 136, 136, 0.2), 0px 5px 8px 0px rgba(136, 136, 136, 0.14), 0px 1px 14px 0px rgba(136, 136, 136, 0.12);
    transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.25s cubic-bezier(0.4, 0, 0.2, 1), transform 0.25s cubic-bezier(0.4, 0, 0.2, 1);
    z-index: 1; }
    #elClassrooms .maof-classroom__title.maof-classroom__item .mdc-text-field {
      margin-top: 2px; }
    .maof-classroom__title.maof-classroom__item.maof-classroom__title.maof-classroom__item--new {
      opacity: 0;
      visibility: hidden;
      transform: translateX(50%); }
  .maof-classroom__new, .maof-classroom__add {
    position: absolute;
    z-index: 1;
    opacity: 0;
    visibility: hidden;
    transition: opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.18s cubic-bezier(0.4, 0, 0.2, 1), transform 0.18s cubic-bezier(0.4, 0, 0.2, 1); }
    .maof-classroom:hover .maof-classroom__new, .maof-classroom:hover .maof-classroom__add {
      opacity: 1;
      visibility: visible; }
    .maof-classroom .maof-classroom__item--active ~ .maof-classroom__new, .maof-classroom .maof-classroom__item--active ~ .maof-classroom__add {
      opacity: 0;
      visibility: hidden; }
  .maof-classroom__new {
    left: -20px;
    top: 25px;
    z-index: 1;
    transform: translateX(50%); }
    .maof-classroom:hover .maof-classroom__new {
      transform: translateX(0); }
    .maof-classroom .maof-classroom__item--active ~ .maof-classroom__new {
      transform: translateX(50%); }
  .maof-classroom__add {
    top: 80px;
    right: 0;
    left: 0;
    margin: 0 auto;
    transform: translateY(-25%); }
    .maof-classroom:hover .maof-classroom__add {
      transform: translateY(0); }
    .maof-classroom .maof-classroom__item--active ~ .maof-classroom__add {
      transform: translateY(-25%); }
  .maof-classroom > ul {
    height: 100%; }
  .maof-classroom__item {
    cursor: move;
    position: relative;
    padding: 29px 45px;
    color: rgba(0, 0, 0, 0.54);
    font-size: 20px;
    line-height: 28px;
    margin-bottom: 5px;
    background: white;
    overflow: hidden;
    box-shadow: 0px 3px 3px -2px rgba(136, 136, 136, 0.2), 0px 3px 4px 0px rgba(136, 136, 136, 0.14), 0px 1px 8px 0px rgba(136, 136, 136, 0.12);
    /*@include mdc-ripple-surface;*/
    transition: opacity 0.25s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.25s cubic-bezier(0.4, 0, 0.2, 1), transform 0.25s cubic-bezier(0.4, 0, 0.2, 1); }
    .maof-classroom__item::before, .maof-classroom__item::after {
      top: calc(50% - 100%);
      /* @noflip */
      left: calc(50% - 100%);
      width: 200%;
      height: 200%; }
    .maof-classroom__item.mdc-ripple-upgraded::after {
      width: var(--mdc-ripple-fg-size, 100%);
      height: var(--mdc-ripple-fg-size, 100%); }
    .maof-classroom__item::before, .maof-classroom__item::after {
      background-color: black; }
    .maof-classroom__item:hover::before {
      opacity: 0.04; }
    .maof-classroom__item:not(.mdc-ripple-upgraded):focus::before, .maof-classroom__item.mdc-ripple-upgraded--background-focused::before {
      transition-duration: 75ms;
      opacity: 0.12; }
    .maof-classroom__item:not(.mdc-ripple-upgraded)::after {
      transition: opacity 150ms linear; }
    .maof-classroom__item:not(.mdc-ripple-upgraded):active::after {
      transition-duration: 75ms;
      opacity: 0.16; }
    .maof-classroom__item.mdc-ripple-upgraded {
      --mdc-ripple-fg-opacity: 0.16; }
    #elClassrooms .maof-classroom__item .mdc-text-field {
      display: block;
      margin-bottom: 0;
      margin-top: 0;
      line-height: 1;
      height: auto;
      pointer-events: none; }
      #elClassrooms .maof-classroom__item .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
        border-bottom-color: transparent; }
      #elClassrooms .maof-classroom__item .mdc-text-field .mdc-floating-label {
        left: 0; }
      #elClassrooms .maof-classroom__item .mdc-text-field .mdc-text-field__input {
        padding-top: 0;
        text-align: center;
        transition: border-color 0.15s cubic-bezier(0.4, 0, 0.2, 1); }
      #elClassrooms .maof-classroom__item .mdc-text-field .mdc-line-ripple {
        display: none; }
    .maof-classroom__item__actions {
      position: absolute;
      bottom: 5px;
      top: 5px;
      left: 5px;
      transition: opacity 0.18s cubic-bezier(0.4, 0, 0.2, 1), visibility 0.18s cubic-bezier(0.4, 0, 0.2, 1); }
      .maof-classroom__item:hover .maof-classroom__item__actions--hover, .maof-classroom__item.maof-classroom__item--active .maof-classroom__item__actions--active {
        opacity: 1;
        visibility: visible;
        pointer-events: all; }
      .maof-classroom__item__actions, .maof-classroom__item.maof-classroom__item--active .maof-classroom__item__actions--hover {
        opacity: 0;
        visibility: hidden;
        pointer-events: none; }
      .maof-classroom__item__actions a {
        display: block;
        position: absolute;
        left: 0;
        text-decoration: none;
        width: 40px;
        height: 40px;
        padding: 10px;
        font-size: 20px; }
        .maof-classroom__item__actions a svg,
        .maof-classroom__item__actions a img {
          width: 20px;
          height: 20px; }
        .maof-classroom__item__actions a:first-of-type {
          top: 0; }
        .maof-classroom__item__actions a:last-of-type {
          bottom: 0; }
    .maof-classroom__item.maof-classroom__item--active {
      cursor: auto; }
      .maof-classroom__item.maof-classroom__item--active::before, .maof-classroom__item.maof-classroom__item--active::after {
        display: none; }
      #elClassrooms .maof-classroom__item.maof-classroom__item--active .mdc-text-field {
        pointer-events: all; }
        #elClassrooms .maof-classroom__item.maof-classroom__item--active .mdc-text-field:not(.mdc-text-field--disabled):not(.mdc-text-field--outlined):not(.mdc-text-field--textarea) .mdc-text-field__input {
          border-bottom-color: rgba(0, 0, 0, 0.42); }
        #elClassrooms .maof-classroom__item.maof-classroom__item--active .mdc-text-field .mdc-line-ripple {
          display: block; }
    .maof-classroom__item.maof-classroom__item--new {
      opacity: 0;
      visibility: hidden;
      transform: translateY(-25%); }
    .maof-classroom__item .mdc-menu {
      right: 0;
      left: 0;
      margin-top: 20px;
      box-shadow: 0px 3px 5px -1px rgba(136, 136, 136, 0.2), 0px 5px 8px 0px rgba(136, 136, 136, 0.14), 0px 1px 14px 0px rgba(136, 136, 136, 0.12); }
      .maof-classroom__item .mdc-menu .mdc-menu__items {
        padding: 0;
        box-shadow: 0 3px 6px #888; }
      .maof-classroom__item .mdc-menu .mdc-list-item {
        border-bottom: 1px solid #000; }
  .maof-classroom.ui-sortable-helper .maof-classroom__title.maof-classroom__item {
    padding-top: 31px;
    padding-bottom: 31px;
    right: 0;
    left: 0;
    box-shadow: 0px 4px 5px -2px rgba(136, 136, 136, 0.2), 0px 7px 10px 1px rgba(136, 136, 136, 0.14), 0px 2px 16px 1px rgba(136, 136, 136, 0.12); }
    #elClassrooms .maof-classroom.ui-sortable-helper .maof-classroom__title.maof-classroom__item > :not(:first-child) {
      display: none; }
  .maof-classroom.ui-sortable-helper .maof-classroom__item {
    margin-bottom: 0;
    background-color: #ff8000;
    color: white;
    box-shadow: 0px 3px 5px -1px rgba(136, 136, 136, 0.2), 0px 5px 8px 0px rgba(136, 136, 136, 0.14), 0px 1px 14px 0px rgba(136, 136, 136, 0.12); }
    .maof-classroom.ui-sortable-helper .maof-classroom__item .mdc-text-field__input {
      color: white; }
      .maof-classroom.ui-sortable-helper .maof-classroom__item .mdc-text-field__input::-webkit-input-placeholder {
        /* Chrome/Opera/Safari */
        color: white; }
      .maof-classroom.ui-sortable-helper .maof-classroom__item .mdc-text-field__input::-moz-placeholder {
        /* Firefox 19+ */
        color: white; }
      .maof-classroom.ui-sortable-helper .maof-classroom__item .mdc-text-field__input:-ms-input-placeholder {
        /* IE 10+ */
        color: white; }
      .maof-classroom.ui-sortable-helper .maof-classroom__item .mdc-text-field__input:-moz-placeholder {
        /* Firefox 18- */
        color: white; }
    .maof-classroom.ui-sortable-helper .maof-classroom__item::before, .maof-classroom.ui-sortable-helper .maof-classroom__item::after, .maof-classroom.ui-sortable-helper .maof-classroom__item__actions {
      display: none; }

.ui-sortable-placeholder {
  min-width: 288px; }
