﻿/// <reference path="App" />
@import "Layout/Header";
@import "Layout/SideNav";
@import "Layout/Panel";

%full-height {
    height: 100%;
}

html {
    @extend %full-height;
    @include mdc-typography-base;
}

body {
    @extend %full-height;
    color: #212121;
}

#elMainContainer {
    @extend %full-height;
    box-sizing: border-box;
    display: flex;
}

#elMainContent {
    position: relative;
    width: 100%;
    overflow: auto;

    main {
        overflow: hidden;
        display: table;
        height: 100%;
        width: 100%;
        @include maof-pad(2);
        box-sizing: border-box;
    }
}
