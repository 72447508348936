﻿@import "Form/Mixins";
/// <reference path="App" />

.maof-form {
    $form: &;
    $margin: 16px;

    &--inline {
        display: inline-block;
    }

    &--compact {
        width: 320px;
    }

    &--double {
        width: 640px;
    }

    &__row {
        $row: &;
        display: table;
        table-layout: fixed;
        width: 100%;
        width: calc(100% + #{$margin});
        margin: 0 #{-1 * $margin / 2};

        &:not(:last-child) {
            margin-bottom: $margin;
        }

        &-group {
            $group: &;
            margin-bottom: $margin;

            #{$row} {
                &:first-child {
                    .maof-form__field {
                        > .mdc-text-field--outlined {
                            margin-top: 4px;
                        }
                    }
                }
            }

            &-wrapper {
                overflow: hidden;

                &[initialized] {
                    #{$group} {
                        overflow: hidden;
                        transition: margin-top 235ms cubic-bezier(.4, 0, .2, 1);
                    }
                }
            }
        }

        %row__field {
            padding: 0 $margin / 2;
            display: table-cell;
            vertical-align: middle;
        }

        #{$form}__field {
            @extend %row__field;

            &:not(:only-child) {
                .mdc-text-field {
                    &--outlined {
                        top: 1px;

                        + .mdc-text-field-helper-text {
                            top: 1px;
                        }
                    }
                }
            }
        }

        #{$form}__buttons {
            @extend %row__field;

            .mdc-button {
                bottom: 1px;
            }
        }
    }

    &__buttons {
        #{$form} > &:last-child {
            margin-top: $margin;
            padding-top: $margin;
            border-top: 1px solid maof-color(neutral);
        }

        .mdc-button {
            min-width: 100px;

            + .mdc-button {
                @include mdc-rtl-reflexive-box(margin, left, $margin);
            }
        }
    }

    .mdc-notched-outline {
        &__idle {
            height: calc(100% - 5px);
        }
    }

    &__field {
        > .mdc-select {
            width: 100%;

            &--outlined {
                .mdc-select__native-control {
                    padding-bottom: 14px;
                }
            }
            /*+ .mdc-text-field-helper-text {
                color: $mdc-text-field-helper-text-color;
                margin: 0 12px;
            }*/
        }

        > .mdc-text-field {
            width: 100%;

            &:not(.mdc-text-field--fullwidth):not(.mdc-text-field--box) {
                margin: 0;
            }

            &--outlined {
                .mdc-text-field__input {
                    height: 32px;
                    padding-bottom: 14px;
                }
            }
            /*+ .mdc-text-field-helper-text {
                margin: 0 12px;
            }*/
        }

        > .mdc-text-field-helper-text:last-child {
            color: $mdc-text-field-helper-text-color;
            margin: 0 12px;
        }

        > .mdc-button {
            width: 100%;
            justify-content: space-between;
            flex-direction: row-reverse;
            @include mdc-button-ink-color(maof-color(medium2));
            @include mdc-button-container-fill-color(maof-color(light2));

            .mdc-button__icon {
                flex: 1;
                margin: 0;
                text-align: right;

                @include mdc-rtl {
                    text-align: left;
                }
            }
        }
    }

    .mdc-button {
        @include mdc-states;
        @include mdc-button-ink-color(maof-color(medium2));

        &--outlined {
            @include mdc-states(maof-color(secondary));
            @include mdc-button-ink-color(maof-color(secondary));
            @include mdc-button-outline-color(maof-color(secondary));
        }

        &--raised, &--unelevated {
            @include mdc-states(maof-color(reset));
            @include mdc-button-ink-color(maof-color(reset));
            @include mdc-button-container-fill-color(maof-color(secondary));
        }
    }

    .maof-file-upload {
        &__button {
            @include mdc-states;
            @include mdc-button-ink-color(maof-color(medium2));
            @include mdc-button-container-fill-color(maof-color(light2));
        }

        &__placeholder {
            @include mdc-rtl-reflexive-box(margin, left, $margin);
            vertical-align: middle;
            color: rgba(0, 0, 0, .54);
        }

        &#{$form}__field .mdc-text-field-helper-text:last-child {
            margin-top: 4px;
        }
    }
}
